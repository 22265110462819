import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { formatDistanceToNow } from "date-fns";

import { Hover, MyContainer } from "../../Components/Styles/GlobalStyle.css";
import WordSpan from "../../Components/WordSpan";
import genericController from "../../controller/generic-controller";
import jobsController from "../../controller/jobs-controller";
import { useAuth } from "../../app-context/auth-user-context";
import { ThreeDotLoading } from '../../Components/react-loading-indicators/Indicator';
import handleErrMsg from "../../Utils/error-handler";
import PaginationLite from "../../Components/PaginationLite";

const FlaggedJobs = () => {
    const navigate = useNavigate();

	const [selectedIndustry, setSelectedIndustry] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState([]);
	const [locationLoading, setLocationLoading] = useState(true);
	const [industryLoading, setIndustryLoading] = useState(true);
	const [jobs, setJobs] = useState([]);
	const [jobsLoading, setJobsLoading] = useState(true);

	const [industryOptions, setIndustryOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);

    // for pagination
    const [pageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
    const [totalJobCount, setTotalJobCount] = useState(0);

    //  data returned from DataPagination
    const [pagedData, setPagedData] = useState([]);

    const { handleRefresh, logout } = useAuth();

    const [reqBody, setReqBody] = useState({
        what: "",
        where: [],
        industries: [],
        limit: pageSize,
		idOffset: 0,
    });

    useEffect( () => {
		initialize();
    }, []);

	const initialize = async () => {
		try {
            const urls = [ '/locations/active', '/industries/active'];
            const response = await genericController.performGetRequests(urls);
            const { 0: locations, 1: industries } = response;

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(locations && locations.data){
				setLocationLoading(false);
                setLocationOptions(locations.data.map( location => ({label: location.name, value: location.name})));
            }

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(industries && industries.data){
				setIndustryLoading(false);
                setIndustryOptions(industries.data.map( industry => ({label: industry.name, value: industry.name})));
            }

			const jobSearchRes = await jobsController.getFlaggedJobs(reqBody, 1);  // 1 represents the pageSpan
			setJobs(jobSearchRes.data.jobs);
			setTotalJobCount(jobSearchRes.data.count);
			setJobsLoading(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	};

	const handleKeyDown = async (event) => {
		if (event.key === 'Enter') {
			const temp = {...reqBody};
			temp.what = event.target.value;
			temp.idOffset = 0;
			setReqBody(temp);
			
			await performCustomSearch(temp);
		}
	}
	
	const handleLocationChange = async (selected) => {
		// https://blog.openreplay.com/async-data-fetching-with-react-select/
		setSelectedLocation(selected);
		const temp = {...reqBody};
		temp.where = selected.map(s => s.value);
		temp.idOffset = 0;
		setReqBody(temp);
		
		await performCustomSearch(temp);
	};

	const handleIndustryChange = async (selected) => {
		// https://blog.openreplay.com/async-data-fetching-with-react-select/
		setSelectedIndustry(selected);
		const temp = {...reqBody};
		temp.industries = selected.length > 0 ? selected.map(s => s.value) : [];
		temp.idOffset = 0;
		setReqBody(temp);
		
		await performCustomSearch(temp);
	};

	const performCustomSearch = async (reqBody) => {
		try {
			resetStates();
			setJobsLoading(true);
			const jobSearchRes = await jobsController.getFlaggedJobs(reqBody, 1);  // 1 represents the pageSpan
			if(jobSearchRes.data.jobs.length < 1){
				toast.info("No Match");
			}
			setJobs(jobSearchRes.data.jobs);
			setTotalJobCount(jobSearchRes.data.count);
			setJobsLoading(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return performCustomSearch(reqBody);
				}
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

    const setPageChanged = async (pageNumber) => {
        const startIndex = (pageNumber - 1) * pageSize;
        if(pageNumber === 1) {
            setPagedData(jobs.slice(startIndex, startIndex + pageSize));
        }else if(pageNumber > 1) {
            if(jobs.length > startIndex) {
                const arr = jobs.slice(startIndex, startIndex + pageSize);
                setPagedData(arr);
            }else {
                // get id of last element in jobs array to use as query offset
                const body = {...reqBody};
                body.idOffset = jobs[jobs.length - 1].id;
                setReqBody(body);
				paginateFetch(body, pageNumber, pageNumber - currentPage);
            }
        }
    };

    const paginateFetch = async (reqBody, pageNumber, pageSpan) => {
        try {
            setJobsLoading(true);
            const response = await jobsController.search(reqBody, pageSpan);

            //  check if the request to fetch indstries doesn't fail before setting values to display
            if(response && response.data){
                setJobs([...jobs, ...response.data.jobs]);
                /*  normally, we would call setPagedData(nonViewed.data.clients) here but that isn't necessary because calling setCurrentPage(pageNumber)
                    would cause PaginationLite to re-render as currentPage is part of it's useEffect dependencies. This re-render triggers setPageChanged to be
                    called with currentPage number. the 'else if' block then executes causing setPagedData to be set  */
                setJobsLoading(false);
            }
            // update page number
            setCurrentPage(pageNumber);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return paginateFetch(reqBody, pageNumber, pageSpan);
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    }

    const resetStates = () => { 
        setJobs([]);
        setCurrentPage(1);
        setTotalJobCount(0);
    }

	const buildCardItem = (job, i) => {
		return <div className="p-2" key={i}>
			<Hover>
				<Link to={`/jobs/reported/${job.id}/${job.title.replace(/[^a-zA-Z0-9 ]/g, '').split(' ').join('+')}`} target="_blank" rel="noopener noreferrer" 
					className="btn text-start border border-secondary-subtle p-3 rounded-3 w-100" style={{boxShadow: 'black 3px 2px 5px'}}
				>
					<div className="mb-2">
						<Badge
							className={`ms-auto text-dark fw-bold bg-primary-subtle fw-bolder align-self-start p-2 me-3`}
						>
							<span>{formatDistanceToNow(job.createdAt, {addSuffix: true})}</span>
						</Badge>
						<Badge
							className={`ms-auto text-dark fw-bold bg-success-subtle fw-bolder align-self-start p-2`}
						>
							<span> {job.available_openings} position{job.available_openings > 1 && "s"} </span>
						</Badge>
					</div>
					<div className="mb-2">
						<h3 className="py-2">
							<WordSpan> {job.title} </WordSpan>
						</h3>
						<div className="d-flex gap-sm-3 flex-column flex-sm-row">
							<small className="fw-bold"> {job.JobLocation.name} </small>
							<small className="fw-bold"> {job.work_type === true ? "full time" : "part time"} </small>
							<small className={(job.min_salary === 0 && job.max_salary === 0) ? 'text-danger' : '' + " fw-bold"}> 
								{(job.min_salary === 0 && job.max_salary === 0) 
									? "Confidential" 
									: `${job.min_salary.toLocaleString('en-US', { style: 'currency', currency: 'NGN' })} up to 
										${job.max_salary.toLocaleString('en-US', { style: 'currency', currency: 'NGN' })}`}
							</small>
						</div>
					</div>
					<div className="mb-2">
						<p>
							{job.summary}
						</p>
					</div>
					<Badge
						className={`ms-auto bg-success-subtle text-secondary fw-bolder align-self-start p-2`}
					>
						{job.JobIndustry.name}
					</Badge>
				</Link>
			</Hover>
		</div>
	};

    const buildJobCards = pagedData.map((job, i) => { return buildCardItem(job, i) });

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			backgroundColor: "#f0f0e8",
			borderColor: "#cecec8ca",
			borderRadius: "2px",
			padding: "10px 0px",
			boxShadow: state.isFocused
				? "0 0 0 0.25rem rgba(0, 123, 255, 0.25)"
				: "none",
			"&:hover": {
				borderColor: "transparent",
			},
			"&:focus": {
				boxShadow: "0 0 0 0.25rem rgba(0, 123, 255, 0.25)",
			},
			// height: "48px", // Match Bootstrap's default form control height
			minHeight: "48px", // Ensures the minimum height is 38px
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "rgba(0, 123, 255, 0.75)", // customize color of the dropdown arrow
		}),
	};

	return (
		<>
			<MyContainer $padding_y="40px" className="container">
				<h3 className="bungee-regular mb-4 ms-3">Search <WordSpan className='bungee-regular'>Reported</WordSpan> Jobs</h3>
				<div className="border py-4 px-5 bg-white-subtle rounded-4" style={{boxShadow: 'black 3px 2px 5px'}}>
					<Row className="align-items-center">
						<Col sm className="d-flex flex-column pt-3 pb-3">
							<label
								className="fw-bold mb-2 fs-6 mt-auto"
								htmlFor="floatingInput1"
							>
								What
							</label>
							<FloatingLabel
								controlId="floatingInput1"
								label="Job title"
								className="mb-1"
							>
								<Form.Control
									className="border border-0 outline-none"
									type="text"
									placeholder="Job Title"
									onKeyDown={handleKeyDown}
								/>
							</FloatingLabel>
						</Col>
						<Col sm className="d-flex flex-column pt-3 pb-3">
							<label
								className="fw-bold mb-2 fs-6 mt-auto"
								htmlFor="floatingInput2"
							>
								Where
							</label>
							<Select
								isMulti
								styles={customStyles}
								placeholder={"Job location"}
								name="where Options"
								onChange={handleLocationChange}
								value={selectedLocation}
								options={locationOptions}
								isLoading={locationLoading}
								className="border-0 bg-secondary"
								classNamePrefix="select"
							/>
						</Col>
						<Col sm className="d-flex flex-column pt-3 pb-3">
							<label
								className="fw-bold mb-2 fs-6 mt-auto"
								htmlFor="floatingInput3"
							>
								Industry
							</label>
							<Select
								isMulti
								styles={customStyles}
								placeholder={"Job sectors..."}
								name="Industry Options"
								onChange={handleIndustryChange}
								value={selectedIndustry}
								options={industryOptions}
								isLoading={industryLoading}
								className="border-0 bg-secondary"
								classNamePrefix="select"
							/>
						</Col>
					</Row>
				</div>

				<div className="mt-5">
					<h3 className="fw-bold ms-3">Found Jobs</h3>
					{ buildJobCards }
				</div>

				<div className="text-center">
					{ jobsLoading && <ThreeDotLoading variant="pulsate" color="#f78419" size="large" /> }
				</div>

				<div className="mt-5">
                    <PaginationLite itemCount={totalJobCount} pageSize={pageSize} setPageChanged={setPageChanged} pageNumber={currentPage} />
				</div>
			</MyContainer>
		</>
	);
};

export default FlaggedJobs;