import React from "react";
import { useAuth } from "../../app-context/auth-user-context";
import ClientDashboard from "./ClientDashboard";
import StaffDashboard from "./StaffDashboard";

const Dashboard = () => {

    const { authUser } = useAuth();
	const user = authUser();

	const display = () => user.accType === undefined ? <StaffDashboard /> : <ClientDashboard /> ;

	return ( <React.Fragment>{ display() }</React.Fragment> );
}

export default Dashboard;