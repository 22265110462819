import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import ErrorMessage from "../../Components/ErrorMessage";
import schema from "../../Utils/yup-schema-validators/add-user-schema";
import staffController from "../../controller/staff-controller";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";

const AddUserPage = () => {
	const navigate = useNavigate();

	const [authsOptions, setAuthsOptions] = useState([]);
	const [authsLoading, setAuthsLoading] = useState(true);
	const [networkRequest, setNetworkREquest] = useState(false);

    const { handleRefresh, logout } = useAuth();

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema.newStaffSchema),
	});

    useEffect( () => {
		initialize();
    }, []);

	const initialize = async () => {
		try {
            const response = await staffController.getAuthorities();

            //check if the request to fetch authorities doesn't fail before setting values to display
            if(response && response.data){
				setAuthsLoading(false);
                setAuthsOptions(response.data.map( auth => ({label: auth.name, value: auth.code})) );
            }
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				// Incase of 401 Unauthorized, navigate to 404
				if(error.response?.status === 401){
					navigate('/dashboard')
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const onSubmit = async (data) => {
		try {
			setNetworkREquest(true);
			// if no authorities selected, set to empty array
			if(!data.authorities){
				data.authorities = [];
			}
			await staffController.register(data);
			setNetworkREquest(false);
			toast.info("Account successfully created");
			reset();
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return onSubmit(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkREquest(false);
		}
	};

	return (
		<>
			<MyContainer $padding_y="30px" className="container">
				<p className="display-6">Add a new User</p>
				<Form className="bg-light p-4 rounded-4 border border-2">
					<h4>User Info</h4>
					<Row className="mb-3">
						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="firstName"
						>
							<Form.Label>Firstname</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Firstname..."
								{...register("fname")}
							/>
							<ErrorMessage source={errors.fname} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="lastname"
						>
							<Form.Label>Lastname</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Lastname..."
								{...register("lname")}
							/>
							<ErrorMessage source={errors.lname} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="email"
						>
							<Form.Label>Email</Form.Label>
							<Form.Control
								required
								type="email"
								placeholder="name@mail.com"
								{...register("email")}
							/>
							<ErrorMessage source={errors.email} />
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="phoneNumber"
						>
							<Form.Label>Phone number</Form.Label>
							<Form.Control
								type="tel"
								placeholder="Phone number..."
								{...register("phone")}
							/>
							<ErrorMessage source={errors.phone} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="gender"
						>
							<Form.Label>Gender</Form.Label>
							<Form.Select
								required
								aria-label="Default select example"
								placeholder="Select..."
								{...register("sex")}
							>
								<option>Select...</option>
								<option value="M">Male</option>
								<option value="F">Female</option>
							</Form.Select>
							<ErrorMessage source={errors.sex} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="authorities"
						>
							<Form.Label>Authorities</Form.Label>
							<Controller
								name="authorities"
								control={control}
								render={({ field: { onChange } }) => (
									<Select
										isMulti
										placeholder="Authorities..."
										{...register("authorities")}
										options={authsOptions}
										onChange={(val) => onChange(val)}
										isLoading={authsLoading}
									/>
								)}
							/>
						</Form.Group>
					</Row>
					<div className="text-center">
						<Button
							variant="primary"
							type="submit"
							disabled={networkRequest}
							onClick={handleSubmit(onSubmit)}
						>
						{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" text='please wait' textColor="#ffffff" /> }
						{!networkRequest && `Add User` }
						</Button>
					</div>
				</Form>
			</MyContainer>
		</>
	);
};

export default AddUserPage;
