import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import fileDownload from "js-file-download";

import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import clientController from "../../controller/client-controller";
import WordSpan from "../../Components/WordSpan";
import ConfirmDialogComp from "../../Components/ConfirmDialogComp";

const EmployerProfilePage = () => {
	let location = useLocation();

    const { handleRefresh, logout } = useAuth();

	const [industryOptions] = useState([]);
	const [client, setClient] = useState([]);

	const [networkRequest, setNetworkRequest] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");

	// TODO: see all posted jobs by this employer

	const {
		register,
		setValue,
		control,
	} = useForm();

	useEffect( () => {
		const arr = location.pathname.split('/');
		const id = arr[arr.length - 2];
        if(id){
            initialize(id);
        }
	}, []);

	const initialize = async (id) => {
		try {
			setNetworkRequest(true);
            const response = await clientController.findById(id);

			if(response && response.data){
                setClient(response.data);

				const selectedIndustries = response.data.JobIndustries.map(industry => ({label: industry.name, value : industry.id}));
				const company_email = response.data.EmployerInfo.company_email;
				const company_name = response.data.EmployerInfo.company_name;
				const address = response.data.EmployerInfo.address;
				const gender = response.data.sex === "M" ? "Male" : 'Female';

				setValue('fname', response.data.fname);
				setValue('lname', response.data.lname);
				setValue('phone', response.data.phone);
				setValue('email', response.data.email);
				setValue('sex', gender);
				setValue('company_name', company_name);
				setValue('company_email', company_email);
				setValue('address', address); 
				setValue('industry', selectedIndustries);
			}
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize(id);
				}
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

    const handleOpenModal = () => {
		let msg = '';
		if(client.status){
			msg = `Suspend ${client.fname} ${client.lname} ?`;
		}else {
			msg = `Activate ${client.fname}  ${client.lname} ?`;
		}
        setDisplayMsg(msg);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

	const handleConfirmAction = async () => {
        setShowModal(false);
		try {
			setNetworkRequest(true);
			const response = await clientController.changeStatus({id: client.id, status: !client.status});
            if(response && response.data){
                setClient(response.data);
            }
			setNetworkRequest(false);
			toast.info("Profile updated successfully");
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return handleConfirmAction();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	}

	const download = async () => {
        try {
            const response = await clientController.staffDownloadClientCV(client.id);
            if(response && response.data){
                fileDownload(response.data, `${client.fname}_${client.lname}_cv${client.EmployerInfo.cv_ext}`);
            }
            console.log(response.data);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return download();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
        }
    }

	return (
		<>
			<MyContainer $padding_y="30px" className="container">
				<p className="display-6 ms-3 fw-bold">
                    Employer <WordSpan>Profile</WordSpan> Page
                    </p>
				<div className="bg-light p-4 rounded-4 border border-2">
					<div className="d-flex gap-2">
                        <h4>User Info:</h4>
                        <h4 className={`fw-bold ${client.status ? 'text-success' : 'text-danger'}`}>
                            {client.status ? "Active" : "Disabled"}
                        </h4>
                    </div>
					<Row className="mb-3">
						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="firstName"
						>
							<Form.Label>Firstname</Form.Label>
							<Form.Control
								disabled
								type="text"
								placeholder="Firstname..."
								{...register("fname")}
							/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="lastname"
						>
							<Form.Label>Lastname</Form.Label>
							<Form.Control
								disabled
								type="text"
								placeholder="Lastname..."
								{...register("lname")}
							/>
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="phoneNumber"
						>
							<Form.Label>Phone number</Form.Label>
							<Form.Control
                                disabled
								type="tel"
								placeholder="Phone number..."
								{...register("phone")}
							/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							controlId="email"
						>
							<Form.Label>Email</Form.Label>
							<Form.Control
                                disabled
								type="text"
								placeholder="email..."
								{...register("email")}
							/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="gender"
						>
							<Form.Label>Gender</Form.Label>
							<Form.Control
                                disabled
								type="tel"
								placeholder="Sex..."
								{...register("sex")}
							/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="company_name"
						>
							<Form.Label>Company Name</Form.Label>
							<Form.Control
                                disabled
								type="text"
								placeholder="company_name..."
								{...register("company_name")}
							/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="company_email"
						>
							<Form.Label>Company Email</Form.Label>
							<Form.Control
                                disabled
								type="text"
								placeholder="company_email..."
								{...register("company_email")}
							/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							controlId="location"
						>
							<Form.Label>Location</Form.Label>
							<Form.Control
                                disabled
								type="text"
								placeholder="address..."
								{...register("address")}
							/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="industry"
						>
							<Form.Label>Industry</Form.Label>
							<Controller
								name="industry"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										isMulti
                                        isDisabled={true}
										placeholder="Industry..."
										{...register("industry")}
										options={industryOptions}
										value={value}
										onChange={(val) => { return onChange(val) }}
									/>
								)}
							/>
						</Form.Group>
					</Row>
					<div className="text-center">
						<Button
							variant={`${client.status === true ? 'danger' : 'success'}`}
							type="submit"
							disabled={networkRequest}
							onClick={() => handleOpenModal()}
						>
							{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
							{ client.status === true ? 'Suspend' : 'Activate' }
						</Button>
					</div>
				</div>
                <ConfirmDialogComp show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmAction} message={displayMsg} />
			</MyContainer>
		</>
	);
};

export default EmployerProfilePage;
