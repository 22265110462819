import React, { useRef, useEffect } from "react";
import * as yup from "yup";

import { Button, Form, Row } from "react-bootstrap";
import ErrorMessage from "../ErrorMessage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';

import Ajv from "ajv";

import editorSchema from "../../Utils/quill-schema";
import Editor from "../quill/quill-editor";
import Quill from 'quill';
const Delta = Quill.import('delta');

const PostJobRequirementForm = ({ setPage, page, formData, setFormData }) => {

    const ajv = new Ajv({allErrors: true}); // options can be passed, e.g. {allErrors: true}

	// Use a ref to access the quill instance directly
	const quillRef = useRef();

	const schema = yup.object().shape({
		summary: yup.string().test('len', 'Job Summary cannot be more than 350 characters', val => val.length < 350).required("Required!"),
	});

	// Yup Integration with "react-hook-form"
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			summary: formData.summary,
		},
	});

    useEffect( () => {
		const quillData = formData.desc;
		let content = new Delta();
		quillData?.forEach(element => {
			content.insert(element.insert, element.attributes)
		});
		quillRef.current.setContents(content);
    }, []);

	const onSubmit = (data) => {
		const { ops } = quillRef.current.getContents();
		const isValid = ajv.validate(editorSchema, ops);
        if (!isValid || quillRef.current.getLength() <= 10) {
			toast.error("Please enter/edit Job Description. Minimum of 10 characters required");
        }else {
			formData.desc = ops;
			formData.summary = data.summary;
			setFormData(formData);
			setPage(++page);
		}
	};

	return (
		<>
			<Form className="bg-light p-4 rounded-4 border border-2">
				<h4 className="lead">Job Description/Summary</h4>
				<Row className="mb-3">
					<Form.Group className="my-2 my-sm-3" controlId="summary">
						<Form.Label className="fw-bold">Job Summary</Form.Label>
						<textarea
							style={{ minHeight: "100px" }}
							className="form-control"
							placeholder="Job summary here..."
							{...register("summary")}
						></textarea>
						<ErrorMessage source={errors.summary} />
					</Form.Group>
					
					{/* div is used to control Quill width, nothing more */}
					<dir>
						<label className="fw-bold mb-2">Job Description</label>
						<Editor ref={quillRef} readOnly={false} />
					</dir>
				</Row>
				<div className="text-center">
					<Button
						variant="primary"
						onClick={() => {
							setPage(--page);
						}}
						className="mx-3"
					>
						Prev
					</Button>
					<Button
						variant="primary"
						type="submit"
						onClick={handleSubmit(onSubmit)}
					>
						Next
					</Button>
				</div>
			</Form>
		</>
	);
};

export default PostJobRequirementForm;
