import React, { useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { toast } from 'react-toastify';

import { MyContainer } from '../Components/Styles/GlobalStyle.css';
import ErrorMessage from '../Components/ErrorMessage';
import WordSpan from "../Components/WordSpan";
import { useAuth } from '../app-context/auth-user-context';
import clientController from '../controller/client-controller';
import staffController from '../controller/staff-controller';
import handleErrMsg from '../Utils/error-handler';
import { ThreeDotLoading } from './react-loading-indicators/Indicator';

const ChangePassword = () => {
    const schema = yup.object().shape({
        current_pw: yup.string().min(6, "Current password must be at least 6 characters").required("Password must be a min of 6 characters!"),
        new_pw: yup.string().min(6, "New password must be at least 6 characters").required("Password must be a min of 6 characters!"),
        confirm_new_pw: yup
        .string()
        .oneOf([yup.ref("new_pw"), null])
        .required("Password Not a match!"),
    });

	const [networkRequest, setNetworkRequest] = useState(false);

	const { authUser, handleRefresh, logout } = useAuth();
	const user = authUser();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        try {
			setNetworkRequest(true);
			let response;
			if(user.accType) {
				response = await clientController.updatePassword(data);

			}else {
				response = await staffController.updatePassword(data);
			}
			setNetworkRequest(false);
			if(response && response.data){
				toast.info(response.data.message);
                reset();
			}
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return onSubmit(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
    };
    
    return (
        <>
            <MyContainer $padding_y="35px">
                <div className="container py-md-5 py-3">
                    <div className="text-center">
                        <h2 className="fw-bold">
                            Change 
                            <WordSpan>
                                <span className="bungee-regular"> Password </span>
                            </WordSpan>
                        </h2>
                    </div>
                    <Form className="d-flex justify-content-center">
                        <div className="bg-light p-4 rounded-4 border border-2">
                            <Row style={{ width: "20rem" }}>
                                <Form.Group className="my-2 my-sm-3" controlId="current_pw" >
                                    <Form.Label>
                                        Current Password
                                    </Form.Label>
                                    <Form.Control
                                        className='w-100'
                                        required
                                        type="password"
                                        placeholder="Password..."
                                        {...register("current_pw")}
                                    />
                                    <ErrorMessage source={errors.current_pw} />
                                </Form.Group>

                                <Form.Group className="my-2 my-sm-3" controlId="new_pw" >
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                            className='w-100'
                                            required
                                            type="password"
                                            placeholder="Password..."
                                            {...register("new_pw")}
                                    />
                                    <ErrorMessage source={errors.new_pw} />
                                </Form.Group>

                                <Form.Group className="my-2 my-sm-3" controlId="confirm_new_pw" >
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control
                                        className='w-100'
                                        type="password"
                                        placeholder="Confirm Password..."
                                        {...register("confirm_new_pw")}
                                    />
                                    <ErrorMessage source={errors.confirm_new_pw} />
                                </Form.Group>
                            </Row>
                            <div className="text-center">
                                <Button className='mt-3 w-75' variant="primary" type="submit" onClick={handleSubmit(onSubmit)} >
                                    { networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
                                    { !networkRequest && `Update` }
                                </Button>
                            </div>

                        </div>
                    </Form>
                </div>
            </MyContainer>
        </>
    )
}

export default ChangePassword;