import Image1 from "./Assets/svg/undraw_updated_resume_re_7r9j.svg";
import Image2 from "./Assets/svg/undraw_interview_re_e5jn.svg";
import Image3 from "./Assets/svg/undraw_educator_re_ju47.svg";
import Image4 from "./Assets/svg/undraw_shared_workspace_re_3gsu.svg";

import Image1Blue from "./Assets/svg/undraw_updated_resume_re_7r9j_blue.svg";
import Image2Blue from "./Assets/svg/undraw_interview_re_e5jn_blue.svg";
import Image3Blue from "./Assets/svg/undraw_educator_re_ju47_blue.svg";
import Image4Blue from "./Assets/svg/undraw_shared_workspace_re_3gsu_blue.svg";

const the_advancement_place_services = [
	{
		name: "Recruitment",
		img_src: require("./Assets/pexels-resumegenius-18848929.jpg"),
		service:
			"Experience unmatched talent acquisition with a touch of eventful and educational entertainment. Connect with exceptional candidates while enjoying engaging experiences that elevate recruitment to new heights",
	},
	{
		name: "Training",
		img_src: require("./Assets/pexels-kampus-8171204.jpg"),
		service:
			"Transform your team with tailored training and expert consultancy, driving growth and success for organizations and individuals",
	},
	{
		name: "Facility",
		img_src: require("./Assets/pexels-heyho-7534170.jpg"),
		service:
			"Transform your environment with our facility management services, specializing in top-tier cleaning and meticulous maintenance for efficient excellence",
	},
	{
		name: "Co-working Space",
		img_src: require("./Assets/pexels-heyho-7534181.jpg"),
		service:
			"Experience our collaborative co-working space with flexible  workstations, modern amenities, and a vibrant community, fostering creativity and productivity to elevate your workday",
	},
];

const quickStart = [
	{
		name: "Looking for Job?",
		text: "Get access to unlimited job offers. Select from varieties of jobs, companies tailored to your taste. You are a click away from your dream job",
		img_src: Image1,
		img_src_blue: Image1Blue,
		email: "employer@gmail.com",
		link: { text: "View Jobs", path: "/jobs" },
	},
	{
		name: "Hire a Candidate",
		text: "Access Premier candidates effortlessly tailored to your company's needs",
		img_src: Image2,
		img_src_blue: Image2Blue,
		email: "job@gmail.com",
		link: { text: "Submit Requirement", path: "/dashboard/post-a-job" },
	},
	{
		name: "Co-operate Training",
		text: "Transform your team with tailored training and expert consultancy, driving growth and success for organizations and individuals",
		img_src: Image3,
		img_src_blue: Image3Blue,
		email: "space@gmail.com",
		link: { text: "Request", path: "/services" },
	},
	{
		name: "Co-working space",
		text: "Experience our collaborative co-working space with flexible  workstations, modern amenities, and a vibrant community, fostering creativity and productivity to elevate your workday",
		img_src: Image4,
		img_src_blue: Image4Blue,
		email: "space@gmail.com",
		link: { text: "Request", path: "/services" },
	},
];

const workTypeOptions = [
	{ value: "Full Time", label: "Full Time" },
	{ value: "Part Time", label: "Part Time" },
];

const statusOptions = [
	{label: "All", value: "-1"},
	{label: "Active", value: "0"},
	{label: "Closed", value: "1"},
	{label: "Suspended", value: "2"},
];

const yearsOptions = [
	{ value: 2025, label: "2025" },
	{ value: 2026, label: "2026" },
	{ value: 2027, label: "2027" },
	{ value: 2028, label: "2028" },
	{ value: 2029, label: "2029" },
	{ value: 2030, label: "2030" },
	{ value: 2031, label: "2031" },
	{ value: 2032, label: "2032" },
	{ value: 2033, label: "2033" },
	{ value: 2034, label: "2034" },
	{ value: 2035, label: "2035" },
	{ value: 2036, label: "2036" },
	{ value: 2037, label: "2037" },
	{ value: 2038, label: "2038" },
	{ value: 2039, label: "2039" },
	{ value: 2040, label: "2040" },
	{ value: 2041, label: "2041" },
	{ value: 2042, label: "2042" },
	{ value: 2043, label: "2043" },
	{ value: 2044, label: "2044" },
	{ value: 2045, label: "2045" },
	{ value: 2046, label: "2046" },
	{ value: 2047, label: "2047" },
	{ value: 2048, label: "2048" },
	{ value: 2049, label: "2049" },
	{ value: 2050, label: "2050" },
];

export {
	the_advancement_place_services,
	quickStart,
	workTypeOptions,
	statusOptions,
	yearsOptions,
};
