import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import ErrorMessage from "../../Components/ErrorMessage";
import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import genericController from "../../controller/generic-controller";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import schema from "../../Utils/yup-schema-validators/add-user-schema";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import staffController from "../../controller/staff-controller";

const StaffProfileEditPage = () => {
	const navigate = useNavigate();

    const { handleRefresh, logout, authUser, updateJWT } = useAuth();
	const user = authUser();

	const [genderOptions] = useState([
		{label: "Male", value: "M"},
		{label: "Female", value: "F"}
	]);

	const [networkRequest, setNetworkRequest] = useState(false);

	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema.staffUpdateSchema),
	});

	useEffect( () => {
		if(user.accType === 'S'){
			navigate('/dashboard/seeker/edit-profile');
			return;
		}
		// fetch staff profile
		initialize();
	}, []);

	const initialize = async () => {
		try {
			setNetworkRequest(true);
            const urls = [ '/staff/profile'];
            const response = await genericController.performGetRequests(urls);
            const { 0: profile } = response;

			if(profile && profile.data){
				const gender = {label: profile.data.sex === "M" ? "Male" : 'Female', value: profile.data.sex};

				setValue('fname', profile.data.fname);
				setValue('lname', profile.data.lname);
				setValue('phone', profile.data.phone);
				setValue('email', profile.data.email);
				setValue('sex', gender);
			}
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const onSubmit = async (data) => {
		try {
			setNetworkRequest(true);
			const response = await staffController.updateProfile(data);

			if(response && response.status === 200){
				updateJWT(response);
			}
			setNetworkRequest(false);
			toast.info("Profile updated successfully");
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return onSubmit(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	};

	return (
		<>
			<MyContainer $padding_y="30px" className="container">
				<p className="display-6 ms-3">Edit Profile</p>
				<Form className="bg-light p-4 rounded-4 border border-2">
					<h4>User Info</h4>
					<Row className="mb-3">
						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="firstName"
						>
							<Form.Label>Firstname</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Firstname..."
								{...register("fname")}
							/>
							<ErrorMessage source={errors.fname} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="lastname"
						>
							<Form.Label>Lastname</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Lastname..."
								{...register("lname")}
							/>
							<ErrorMessage source={errors.lname} />
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="phoneNumber"
						>
							<Form.Label>Phone number</Form.Label>
							<Form.Control
								type="tel"
								placeholder="Phone number..."
								{...register("phone")}
							/>
							<ErrorMessage source={errors.phone} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="gender"
						>
							<Form.Label>Gender</Form.Label>
							<Controller
								name="sex"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										required
										placeholder="Gender..."
										{...register("sex")}
										options={genderOptions}
										onChange={(val) => onChange(val)}
										value={value}
									/>
								)}
							/>
							<ErrorMessage source={errors.sex} />
						</Form.Group>

                        <Form.Group
                            className="my-2 my-sm-3"
                            as={Col}
                            sm="6"
                            controlId="email"
                        >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="name@mail.com"
                                {...register("email")}
                            />
                            <ErrorMessage source={errors.email} />
                        </Form.Group>
					</Row>
					<div className="text-center">
						<Button variant="primary" type="submit" disabled={networkRequest} onClick={handleSubmit(onSubmit)} >
							{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
							{ !networkRequest && `Update Profile` }
						</Button>
					</div>
				</Form>
			</MyContainer>
		</>
	);
};

export default StaffProfileEditPage;
