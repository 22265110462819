import React, { useState } from "react";

import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import ErrorMessage from "../ErrorMessage";
import clientController from "../../controller/client-controller";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import genericController from "../../controller/generic-controller";
import { ThreeDotLoading } from "../react-loading-indicators/Indicator";

const TokenVerificationForm = ({ setPage, formData, accType }) => {
	const navigate = useNavigate();

    const { updateJWT, handleRefresh, logout } = useAuth();

	// for otp, disable buttons
	const [networkRequest, setNetworkRequest] = useState(false);
	const [otpRequest, setOtpRequest] = useState(false);

	const schema = yup.object().shape({
		otp: yup.string().required("OTP is required!"),
		terms_and_conditions: yup
			.boolean()
			.isTrue("Please accept the Terms and Conditions to continue!")
			.required("Required!"),
	});

	// Yup Integration with "react-hook-form"
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (otpData) => {
		try {
			setNetworkRequest(true);
			let response;
			if(accType === 'S') {
				response = await clientController.registerSeeker(formData, otpData);

			}else {
				response = await clientController.registerEmployer(formData, otpData);
			}
			updateJWT(response);
			setNetworkRequest(false);
			setOtpRequest(false);
			toast.info("Congratulations. Account successfully created.")
			navigate('/jobs');
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return onSubmit(otpData);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
			setOtpRequest(false);
		}
	};

	const requestOTP = async () => {
		// while requesting otp disable all three buttons
		try {
			setNetworkRequest(true);
			toast.info(`sending OTP to ${formData.email}.`);
			// send otp to email
			await genericController.requestOTP(formData.email);
			toast.info(`OTP sent to ${formData.email}. If not found in your inbox, please check you spam`);
			setNetworkRequest(false);
		} catch (error) {
			toast.error(handleErrMsg(error));
			setNetworkRequest(false);
		}
	}

	return (
		<div>
			<Form className="bg-light p-4 rounded-4 border border-2">
				<h4 className="text-center">Verification</h4>
				<Row className="mb-3">
					<div className="d-flex flex-column align-items-center">
						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="otp"
						>
							<p className="text-success text-center fw-6 p-0 mb-3">
									Message sent to inbox, check spam if not found!
							</p>
							<Form.Label>O T P</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Token"
								{...register("otp")}
							/>
							<ErrorMessage source={errors.otp} />
						</Form.Group>
						<div className="text-center">
							<Form.Group
								className="d-flex justify-content-center gap-1"
								controlId="terms_and_conditions"
							>
								<Controller
									name="terms_and_conditions"
									control={control}
									render={({ field }) => (
										<Form.Check
											type="checkbox"
											label="I accept the"
											checked={field.value}
											onChange={(e) => {
												field.onChange(e.target.checked);
											}}
										/>
									)}
								/>
								<Link to={`/terms-and-agreement`} target="_blank" rel="noopener noreferrer">Terms and Conditions</Link>
							</Form.Group>
							<ErrorMessage source={errors.terms_and_conditions} />
						</div>
					</div>
				</Row>
				<div className="text-center d-flex flex-column align-items-center flex-sm-row justify-content-center gap-3">
					<Button
						variant="primary"
						onClick={() => {
							setPage(1);
						}}
						className="mx-3"
						disabled={networkRequest || otpRequest}
						style={{ minWidth: "11rem" }}
					>
						Go Back
					</Button>
					<Button
						variant="primary"
						type="submit"
						onClick={handleSubmit(onSubmit)}
						disabled={networkRequest || otpRequest}
						style={{ width: "11rem" }}
					>
						{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
						{ !networkRequest && `Continue` }
					</Button>
					<Button
						variant="primary" style={{ width: "11rem" }}
						onClick={() => requestOTP()}
						className="mx-3"
						disabled={networkRequest || otpRequest}
					>
						{ otpRequest && <ThreeDotLoading color="#ffffff" size="medium" text='please wait' textColor="#ffffff" /> }
						{ !otpRequest && `Request OTP` }
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default TokenVerificationForm;
