import React, { useEffect, useState } from "react";
import { CloseButton, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as Icon1 } from "../../Assets/svg/key-solid.svg";
import { ReactComponent as Icon2 } from "../../Assets/svg/users-solid.svg";
import { ReactComponent as Icon3 } from "../../Assets/svg/square-plus-solid.svg";
import { ReactComponent as Icon5 } from "../../Assets/svg/bell-solid.svg";
import { ReactComponent as Icon6 } from "../../Assets/svg/user-gear-solid.svg";
import WordSpan from "../../Components/WordSpan";
import ConfirmDialogComp from "../../Components/ConfirmDialogComp";
import { useAuth } from "../../app-context/auth-user-context";
import clientController from "../../controller/client-controller";
import handleErrMsg from "../../Utils/error-handler";

const ClientDashboard = () => {
    const navigate = useNavigate();

    const { authUser, handleRefresh, logout } = useAuth();
    const user = authUser();

    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");
    const [selectedId, setSelectedId] = useState(0);
    
    const handleCloseModal = () => setShowModal(false);

    const handleOpenModal = (id, name) => {
        setDisplayMsg(`Are you sure you want to remove ${name} from your list of industries?`);
        setSelectedId(id);
        setShowModal(true);
    };

    const [myIndustires, setMyIndustries] = useState([]);

    useEffect( () => {
        // fetch client industries
        fetchMyIndustries();
    }, []);

    const fetchMyIndustries = async () => {
        try {
            const response = await clientController.findMyIndustries();

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(response && response.data){
              setMyIndustries(response.data);
            }
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                  await handleRefresh();
                  return fetchMyIndustries();
                }
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    };

    const showMyIndustries = () => {
        return myIndustires.map(({ id, name }, index) => (
            <div key={index} className="p-3 bg-success-subtle text-dark py-2 fs-6 d-flex align-items-center justify-content-between" >
                <p className="me-2 m-0">{name}</p>
                <CloseButton className="" onClick={() => handleOpenModal(id, name)} aria-label="Hide" />
            </div>
        ));
    };

    const handleConfirmAction = async () => {
        setShowModal(false);
        try {
            await clientController.removeMyIndustry(selectedId);
            // update UI
            setMyIndustries(myIndustires.filter(i => i.id !== selectedId ));
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                  await handleRefresh();
                  return handleConfirmAction();
                }
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    };

    const buildQuickMenu = () => {
        return <div className="container mt-4 mb-4">
            <div className="row g-2">
                {user.accType === 'E' && <div class="col-md-4 col-sm-12">
                    <div className="btn p-3 border w-100" onClick={() => navigate('post-a-job')} style={{minHeight: '110px'}}>
                        <div className="d-flex align-items-center gap-3">
                            <div className={`p-2 bg-danger-subtle text-center rounded-3 onHover`} style={{boxShadow: 'black 3px 2px 5px'}}>
                                <Icon3 width={50} height={40}
                                />
                            </div>
                            <div className="d-flex flex-column text-start">
                                <span className="fs-6 noto-sans-font fw-bold">Post a Job</span>
                                <span className="text-muted">Post a job for job seekers to apply</span>
                            </div>
                        </div>
                    </div>
                </div>}
                {[
                    { title: "My Jobs", IconSrc: Icon2, link: "client/my-jobs", desc: user.accType === 'E' ? 'view collections of your posted jobs' : 'View collections of your applied jobs', bg: 'bg-warning-subtle' },
                    { title: "Change Password", IconSrc: Icon1, link: "change-pw", desc: 'Change your password', bg: 'bg-info-subtle' },
                    { title: "Edit Profile", IconSrc: Icon6, link: user.accType === 'E' ? 'employer/edit-profile' : 'seeker/edit-profile', desc: 'Adjust your profile', bg: 'bg-success-subtle' },
                    { title: "Notifications", IconSrc: Icon5, link: "notifications-page", desc: 'View Notifications', bg: 'bg-secondary-subtle'},
                ].map(({ title, IconSrc, link, desc, bg }, idx) => (
                    <div className="col-md-4 col-sm-12" key={idx}>
						<div className="btn p-3 border w-100" onClick={() => navigate(link)} style={{minHeight: '120px'}}>
							<div className="d-flex align-items-center gap-3">
                                <div className={`p-2 ${bg} text-center rounded-3`} style={{boxShadow: 'black 3px 2px 5px'}}>
                                    <IconSrc width={50} height={40} />
                                </div>
								<div className="d-flex flex-column text-start">
									<span className="fs-6 noto-sans-font fw-bold">{title}</span>
									<span className="text-muted">{desc}</span>
								</div>
							</div>
						</div>
					</div>
                ))}
            </div>
        </div>
    }

    return (
        <div className="py-2 py-md-4">
            <div className="container-md">
                <h1 className="fw-bold mt-4">
                    Hello{" "}
                    <WordSpan>
                    <span className="bungee-regular"> {user.firstName} </span>
                    </WordSpan>
                </h1>

                <p>Welcome to your Dashboard. Use the <strong>Quick Menu</strong> below to explore a lot of features</p>

                <h3 className="mt-5 fw-bold noto-sans-font">
                    Quick<WordSpan color={"lightBlue"}> Menu</WordSpan>
                </h3>
                <hr />
                {buildQuickMenu()}
                <hr />
                <div className="bg-light p-4 rounded-4 border border-2 mt-4">
                    <Form className="bg-light p-4 rounded-4 border border-2">
                        <div className="d-flex gap-2">
                            <h4 >Basic Info: </h4>
                            <div >
                                <h4> <WordSpan > {user.accType === 'E' ? ' Employer' : ' Seeker'} </WordSpan> </h4>
                            </div>
                        </div>
                        <Row className="mb-3">
                            <Form.Group
                                className="my-2 my-sm-3"
                                as={Col}
                                sm="6"
                                controlId="firstName"
                            >
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                required
                                type="text"
                                placeholder="Firstname..."
                                readOnly
                                disabled
                                defaultValue={user.firstName}
                                />
                            </Form.Group>

                            <Form.Group
                                className="my-2 my-sm-3"
                                as={Col}
                                sm="6"
                                controlId="lastname"
                            >
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Lastname..."
                                    readOnly
                                    disabled
                                    defaultValue={user.lastName}
                                />
                            </Form.Group>

                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                                controlId="phoneNumber"
                            >
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                type="tel"
                                placeholder="Phone Number..."
                                readOnly
                                disabled
                                defaultValue={user.phone}
                                />
                            </Form.Group>

                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                                controlId="email"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                type="email"
                                placeholder="Email..."
                                readOnly
                                disabled
                                defaultValue={user.email}
                                />
                            </Form.Group>

                            <Form.Group
                                className="my-2 my-sm-3"
                                as={Col}
                                sm="6"
                                controlId="gender"
                            >
                                <Form.Label>Gender</Form.Label>
                                <Form.Control
                                required
                                aria-label="Default select example"
                                placeholder="Select..."
                                defaultValue={user.sex === 'M' ? 'Male' : 'Female'}
                                disabled
                                >
                                </Form.Control>
                            </Form.Group>

                            <div className="my-3">
                                <h4>Industries</h4>
                                <div className="d-flex gap-2 flex-wrap">
                                    {showMyIndustries()}
                                </div>

                                <ConfirmDialogComp
                                    show={showModal}
                                    handleClose={handleCloseModal}
                                    handleConfirm={handleConfirmAction}
                                    message={displayMsg}
                                />
                            </div>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ClientDashboard;