import React, { useState, useEffect } from "react";
import { Badge, Button, CloseButton, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import ErrorMessage from "../../Components/ErrorMessage";
import ConfirmDialogComp from "../../Components/ConfirmDialogComp";
import { yearsOptions } from "../../the-advancement-place";
import { ReactComponent as Icon1 } from "../../Assets/svg/arrow-rotate-left-solid.svg"
import { DisabledDiv, MyContainer } from "../../Components/Styles/GlobalStyle.css";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import genericController from "../../controller/generic-controller";
import WordSpan from "../../Components/WordSpan";
import { CommetLoading, OribitalLoading, ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import staffController from "../../controller/staff-controller";
import settingsValidation from '../../Utils/yup-schema-validators/settings-validation';

const SettingsPage = () => {
    const navigate = useNavigate();

    const { handleRefresh, logout } = useAuth();

    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");
    const [reqBody, setReqBody] = useState({});

    const [industryOptions, setIndustryOptions] = useState([]);
    const [selectedIndustryId, setSelectedIndustryId] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocationId, setSelectedLocationId] = useState([]);
    const [qualificationOptions, setQualificationOptions] = useState([]);
    const [selectedQualificationId, setSelectedQualificationId] = useState([]);
    const [experienceOptions, setExperienceOptions] = useState([]);
    const [selectedExperienceId, setSelectedExperienceId] = useState([]);

    const [updatingYear, setUpdatingYear] = useState(false);
    const [creatingLocation, setCreatingLocation] = useState(false);
    const [creatingIndustry, setCreatingIndustry] = useState(false);
    const [creatingExperience, setCreatingExperience] = useState(false);
    const [creatingQualification, setCreatingQualification] = useState(false);
    const [locationLoading, setLocationLoading] = useState(true);
    const [industryLoading, setIndustryLoading] = useState(true);
    const [experienceLoading, setExperienceLoading] = useState(true);
    const [qualificationLoading, setQualificationLoading] = useState(true);

    const {
        handleSubmit: handleIndustrySubmit,
        register: registerIndustry,
        formState: { errors: industryErrors },
    } = useForm({
		resolver: yupResolver(settingsValidation.industrySchema),
	});

    const {
        handleSubmit: handleLocationSubmit,
        register: registerLocation,
        formState: { errors: locationErrors },
    } = useForm({
		resolver: yupResolver(settingsValidation.locationSchema),
	});

    const {
        handleSubmit: handleExperienceSubmit,
        register: registerExperience,
        formState: { errors: experienceErrors },
    } = useForm({
		resolver: yupResolver(settingsValidation.experienceSchema),
	});

    const {
        handleSubmit: handleQualificationSubmit,
        register: registerQualification,
        formState: { errors: qualificationErrors },
    } = useForm({
		resolver: yupResolver(settingsValidation.qualificationSchema),
	});

    const {
        control: yearControl,
        handleSubmit: handleYearSubmit,
        register: registerYear,
        formState: { errors: yearErrors },
    } = useForm({
		resolver: yupResolver(settingsValidation.yearSchema),
	});

    useEffect( () => {
        initialize();
    }, []);

    const initialize = async () => {
        try {
            const urls = [ '/locations', '/industries', '/experience', '/qualifications'];
            const response = await genericController.performGetRequests(urls);
            const { 0: locations, 1: industries, 2: experience, 3: qualifications } = response;

            //check if the request to fetch locations doesn't fail before setting values to display
            if(locations && locations.data){
                setLocationLoading(false);
                setLocationOptions(locations.data);
            }

              //check if the request to fetch indstries doesn't fail before setting values to display
            if(industries && industries.data){
                setIndustryLoading(false);
                setIndustryOptions(industries.data);
            }

            //check if the request to fetch qualifications doesn't fail before setting values to display
            if(qualifications && qualifications.data){
                setQualificationLoading(false);
                setQualificationOptions(qualifications.data);
            }

            //check if the request to fetch experience doesn't fail before setting values to display
            if(experience && experience.data){
                setExperienceLoading(false);
                setExperienceOptions(experience.data);
            }
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return initialize();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    };

    const handleOpenModal = (id, value, section, msg) => {
        setDisplayMsg(msg);
        const temp = {
            id,
            status: value,
            value,
            section,
        }
        switch (section) {
            case 'industry':
                setSelectedIndustryId(id);
                break;
            case 'location':
                setSelectedLocationId(id);
                break;
            case 'experience':
                setSelectedExperienceId(id);
                break;
            case 'qualification':
                setSelectedQualificationId(id);
                break;
            case 'year':
                break;
        }
        setReqBody(temp);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleConfirmAction = () => {
        setShowModal(false);
        // Perform the action here
        let temp;
        switch (reqBody.section) {
            case 'industry':
                performIndustryStatusUpdate();
                break;
            case 'location':
                performLocationStatusUpdate();
                break;
            case 'experience':
                performExperienceStatusUpdate();
                break;
            case 'qualification':
                performQualificationStatusUpdate();
                break;
            case 'year':
                performCurrentYearUpdate();
                break;
            case 'create-industry':
                performCreateIndustry();
                break;
            case 'create-location':
                performCreateLocation();
                break;
            case 'create-qualification':
                performCreateQualification();
                break;
            case 'create-experience':
                performCreateExperience();
                break;
        }
    };

    const performIndustryStatusUpdate = async () => {
        try {
            setIndustryLoading(true);
            const response = await staffController.updateIndustryStatus(reqBody);
            if(response.status === 200){
                const industry = industryOptions.find(i => i.id === selectedIndustryId);
                industry.status = reqBody.status;
                const temp = [...industryOptions];
                setIndustryOptions(temp); 
            }
            setIndustryLoading(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performIndustryStatusUpdate();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setIndustryLoading(false);
        }
    };

    const performLocationStatusUpdate = async () => {
        try {
            setLocationLoading(true);
            const response = await staffController.updateLocationStatus(reqBody);
            if(response.status === 200){
                const location = locationOptions.find(i => i.id === selectedLocationId);
                location.status = reqBody.status;
                const temp = [...locationOptions];
                setLocationOptions(temp); 
            }
            setLocationLoading(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performLocationStatusUpdate();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setLocationLoading(false);
        }
    };

    const performExperienceStatusUpdate = async () => {
        try {
            setExperienceLoading(true);
            const response = await staffController.updateExperienceStatus(reqBody);
            if(response.status === 200){
                const experience = experienceOptions.find(i => i.id === selectedExperienceId);
                experience.status = reqBody.status;
                const temp = [...experienceOptions];
                setExperienceOptions(temp); 
            }
            setExperienceLoading(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performExperienceStatusUpdate();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setExperienceLoading(false);
        }
    };

    const performQualificationStatusUpdate = async () => {
        try {
            setQualificationLoading(true);
            const response = await staffController.updateQualificationStatus(reqBody);
            if(response.status === 200){
                const qualification = qualificationOptions.find(i => i.id === selectedQualificationId);
                qualification.status = reqBody.status;
                const temp = [...qualification];
                setQualificationOptions(temp); 
            }
            setQualificationLoading(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performQualificationStatusUpdate();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setQualificationLoading(false);
        }
    };

    const performCurrentYearUpdate = async () => {
        try {
            setUpdatingYear(true);
            await staffController.updateCurrentYear(reqBody);
            setUpdatingYear(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performCurrentYearUpdate();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setUpdatingYear(false);
        }
    };

    const performCreateIndustry = async () => {
        try {
            setCreatingIndustry(true);
            const response = await staffController.addIndustry(reqBody);
            if(response && response.data){
                setIndustryOptions([...industryOptions, response.data]);
            }
            setCreatingIndustry(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performCreateIndustry();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setCreatingIndustry(false);
        }
    };

    const performCreateLocation = async () => {
        try {
            setCreatingLocation(true);
            const response = await staffController.addLocation(reqBody);
            if(response && response.data){
                setLocationOptions([...locationOptions, response.data]);
            }
            setCreatingLocation(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performCreateLocation();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setCreatingLocation(false);
        }
    };

    const performCreateExperience = async () => {
        try {
            setCreatingExperience(true);
            const response = await staffController.addExperience(reqBody);
            if(response && response.data){
                setExperienceOptions([...experienceOptions, response.data]);
            }
            setCreatingExperience(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performCreateExperience();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setCreatingExperience(false);
        }
    };

    const performCreateQualification = async () => {
        try {
            setCreatingQualification(true);
            const response = await staffController.addQualification(reqBody);
            if(response && response.data){
                setQualificationOptions([...qualificationOptions, response.data]);
            }
            setCreatingQualification(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performCreateQualification();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
            setCreatingQualification(false);
        }
    };

    const buildIndustryBadges = () => {
        return industryOptions.map(({ id, name, status }, index) => (
            <div key={index}  className={`${status ? 'bg-success-subtle' : 'bg-danger-subtle'} text-dark rounded-3 fw-bold p-2 fs-6 d-flex align-items-center justify-content-between`} >
                <small className="pe-2 me-2 m-0">{name}</small>
                <div className="d-flex gap-2">
                    {!status && <Icon1  className="btn p-0" width={"20"} onClick={() => handleOpenModal(id, true, 'industry', 
                        `Restore ${name} to list of industries?`
                    )} />}
                    { status && 
                        <CloseButton className="p-0" 
                            onClick={() => handleOpenModal(id, false, 'industry', `Remove ${name} from list of industries?` )} aria-label="Hide" />
                    }
                </div>
            </div>
        ));
    };

    const buildLocationBadges = () => {
        return locationOptions.map(({ id, name, status }, index) => (
            <div key={index}  className={`${status ? 'bg-success-subtle' : 'bg-danger-subtle'} text-dark rounded-3 fw-bold p-2 fs-6 d-flex align-items-center justify-content-between`} >
                <small className="pe-2 me-2 m-0">{name}</small>
                <div className="d-flex gap-2">
                    {!status && <Icon1  className="btn p-0" width={"20"} onClick={() => handleOpenModal(id, true, 'location', 
                        `Restore ${name} to list of locations?`
                    )} />}
                    { status && 
                        <CloseButton className="p-0" 
                            onClick={() => handleOpenModal(id, false, 'location', `Remove ${name} from list of locations?` )} aria-label="Hide" />
                    }
                </div>
            </div>
        ));
    };

    const buildQualificationBadges = () => {
        return qualificationOptions.map(({ id, name, status }, index) => (
            <div key={index}
                className={`${status ? 'bg-success-subtle' : 'bg-danger-subtle'} text-dark rounded-3 fw-bold p-2 fs-6 d-flex align-items-center justify-content-between`} >
                <small className="pe-2 me-2 m-0">{name}</small>
                <div className="d-flex gap-2">
                    { !status && <Icon1 className="btn p-0" width={"20"} onClick={() => handleOpenModal(id, true, 'qualification', 
                        `Restore ${name} to list of qualifications?`)} /> }
                    { status && 
                        <CloseButton className="p-0" 
                            onClick={() => handleOpenModal(id, false, 'qualification', `Remove ${name} from list of qualifications?` )} aria-label="Hide" /> }
                </div>
            </div>
        ));
    }

    const buildExperienceBadges = () => {
        return experienceOptions.map(({ id, name, status }, index) => (
            <div key={index}
                className={`${status ? 'bg-success-subtle' : 'bg-danger-subtle'} text-dark rounded-3 fw-bold p-2 fs-6 d-flex align-items-center justify-content-between`} >
                <small className="pe-2 me-2 m-0">{name}</small>
                <div className="d-flex gap-2">
                    { !status && <Icon1 className="btn p-0" width={"20"} onClick={() => handleOpenModal(id, true, 'experience', 
                        `Restore ${name} to list of experiences?`)} /> }
                    { status && 
                        <CloseButton className="p-0" 
                            onClick={() => handleOpenModal(id, false, 'experience', `Remove ${name} from list of experiences?` )} aria-label="Hide" /> }
                </div>
            </div>
        ));
    }

    const createIndustry = (data) => {
        handleOpenModal(null, data.industry, 'create-industry', `Create new industry ${data.industry} ?` );
    }

    const createLocation = (data) => {
        handleOpenModal(null, data.location, 'create-location', `Create new location ${data.location} ?` );
    }

    const createQualification = (data) => {
        handleOpenModal(null, data.qualification, 'create-qualification', `Create new qualification ${data.qualification} ?` );
    }

    const createExperience = (data) => {
        handleOpenModal(null, data.experience, 'create-experience', `Create new experience ${data.experience} ?` );
    }

    const updateYear = (data) => {
        handleOpenModal(null, data.year, 'year', `Update current year to ${data.year} ?` );
    }

    return (
        <MyContainer $padding_y="2rem" className="container">
            <div className="bg-light p-4 rounded-4 border border-2">
                <div className="bg-light p-4 rounded-4 border border-2">
                    <div className="d-flex justify-content-center mb-1">
                        <h1>
                            <WordSpan>
                                <span className="bungee-regular"> Settings </span>
                            </WordSpan>
                        </h1>
                    </div>

                    {/* <Form>
                        <Form.Group controlId="current_year">
                            <Row className="mb-3">
                                <Col md="3" className=" d-flex align-items-center">
                                    <Form.Label>Current Year</Form.Label>
                                </Col>
                                <Col className="my-2 my-md-0" md="6">
                                    <Controller
                                        name="year"
                                        control={yearControl}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                placeholder="Current Year..."
                                                options={yearsOptions}
                                                {...registerYear("year")}
                                                onChange={(val) => onChange(val.value)}
                                                value={yearsOptions.find((e) => e.value === value)}
                                            />
                                        )}
                                    />
                                    <ErrorMessage source={yearErrors.year} />
                                </Col>
                                <Col className="my-2 my-md-0 d-flex justify-content-center justify-content-md-start" md={"3"}>
                                    <Button className="w-75" onClick={handleYearSubmit(updateYear)} disabled={updatingYear}>
                                        Update
                                        { updatingYear && <ThreeDotLoading color="#ffffff" size="small" variant = "pulsate" />}
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <hr /> */}

                    <Form>
                        <Form.Group controlId="industry">
                            <Row className="mb-3">
                                <Col md="3" className=" d-flex align-items-center">
                                    <Form.Label>Industry</Form.Label>
                                </Col>
                                <Col className="my-2 my-md-0" md="6">
                                    <Form.Control required type="text" placeholder="Industry..." {...registerIndustry("industry")} />
                                    <ErrorMessage source={industryErrors.industry} />
                                </Col>
                                <Col className="my-2 my-md-0 d-flex justify-content-center justify-content-md-start" md={"3"}>
                                    <Button className="w-75" onClick={handleIndustrySubmit(createIndustry)} disabled={creatingIndustry}>
                                        Create
                                        { creatingIndustry && <ThreeDotLoading color="#ffffff" size="small" variant = "pulsate" />}
                                    </Button>
                                </Col>
                            </Row>

                            <DisabledDiv className="d-flex flex-column">
                                { industryLoading && <ThreeDotLoading color="#000000" size="large" variant = "pulsate" /> }
                                <div className={`d-flex gap-2 flex-wrap mt-3 ${industryLoading ? 'disabled' : ''}`}>
                                    { buildIndustryBadges() }
                                </div>
                            </DisabledDiv>
                        </Form.Group>
                    </Form>
                    <hr />

                    <Form.Group controlId="location">
                        <Row className="mb-3">
                            <Col md="3" className=" d-flex align-items-center">
                                <Form.Label>Location</Form.Label>
                            </Col>
                            <Col md="6">
                                <Form.Control required type="text" placeholder="Location..." {...registerLocation("location")} />
                                <ErrorMessage source={locationErrors.location} />
                            </Col>
                            <Col className="my-2 my-md-0 d-flex justify-content-center justify-content-md-start" md={"3"}>
                                <Button className="w-75" onClick={handleLocationSubmit(createLocation)} disabled={creatingLocation}>
                                    Create
                                    { creatingLocation && <ThreeDotLoading color="#ffffff" size="small" variant = "pulsate" />}
                                </Button>
                            </Col>
                        </Row>

                        <DisabledDiv className="d-flex flex-column">
                            { locationLoading && <ThreeDotLoading color="#000000" size="large" variant = "windmill" /> }
                            <div className={`d-flex gap-2 flex-wrap mt-3 ${locationLoading ? 'disabled' : ''}`}>
                                { buildLocationBadges() }
                            </div>
                        </DisabledDiv>
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="qualification">
                        <Row className="mb-3">
                            <Col md="3" className=" d-flex align-items-center">
                                <Form.Label>Qualification</Form.Label>
                            </Col>
                            <Col md="6">
                                <Form.Control required type="text" placeholder="Qualification..." {...registerQualification("qualification")} />
                                <ErrorMessage source={qualificationErrors.qualification} />
                            </Col>
                            <Col className="my-2 my-md-0 d-flex justify-content-center justify-content-md-start" md={"3"}>
                                <Button className="w-75" onClick={handleQualificationSubmit(createQualification)} disabled={creatingQualification}>
                                    Create
                                    { creatingQualification && <ThreeDotLoading color="#ffffff" size="small" variant = "pulsate" />}
                                </Button>
                            </Col>
                        </Row>

                        <DisabledDiv className="d-flex flex-column">
                            { qualificationLoading && <OribitalLoading color="#000000" size="small" variant = "track-disc" /> }
                            <div className={`d-flex gap-2 flex-wrap mt-3 ${qualificationLoading ? 'disabled' : ''}`}>
                                { buildQualificationBadges() }
                            </div>
                        </DisabledDiv>
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="experience">
                        <Row className="mb-3">
                            <Col md="3" className=" d-flex align-items-center">
                                <Form.Label>Experience</Form.Label>
                            </Col>
                            <Col md="6">
                                <Form.Control required type="text" placeholder="Experience..." {...registerExperience("experience")} />
                                <ErrorMessage source={experienceErrors.experience} />
                            </Col>
                            <Col className="my-2 my-md-0 d-flex justify-content-center justify-content-md-start" md={"3"}>
                                <Button className="w-75" onClick={handleExperienceSubmit(createExperience)} disabled={creatingExperience} >
                                    Create
                                    { creatingExperience && <ThreeDotLoading color="#ffffff" size="small" variant = "pulsate" />}
                                </Button>
                            </Col>
                        </Row>

                        <DisabledDiv className="d-flex flex-column">
                            { experienceLoading && <CommetLoading color="#000000" size="small" /> }
                            <div className={`d-flex gap-2 flex-wrap mt-3d-flex gap-2 flex-wrap mt-3 ${experienceLoading ? 'disabled' : ''}`}>
                                { buildExperienceBadges() }
                            </div>
                        </DisabledDiv>
                    </Form.Group>
                    <hr />

                    <Row className="mb-3">
                        <div className="my-3">
                            <h4>Review <Link to={"/terms-and-agreement/review"}>Terms and Agreement</Link></h4>
                        </div>
                    </Row>
                </div>
                <ConfirmDialogComp show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmAction} message={displayMsg} />
            </div>
        </MyContainer>
    );
}

export default SettingsPage;