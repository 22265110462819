import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import ErrorMessage from "../../Components/ErrorMessage";
import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import genericController from "../../controller/generic-controller";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import clientSchema from "../../Utils/yup-schema-validators/client-schema";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import clientController from "../../controller/client-controller";

const EmployerProfileEditPage = () => {
	const navigate = useNavigate();

    const { handleRefresh, logout, authUser, updateJWT } = useAuth();
	const user = authUser();

	const [genderOptions] = useState([
		{label: "Male", value: "M"},
		{label: "Female", value: "F"}
	]);
	const [industryOptions, setIndustryOptions] = useState([]);

	const [networkRequest, setNetworkRequest] = useState(false);
	const [industryLoading, setIndustryLoading] = useState(true);

	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(clientSchema.employerUpdateSchema),
	});

	useEffect( () => {
		if(user.accType === 'S'){
			navigate('/dashboard/seeker/edit-profile');
			return;
		}
		// fetch client industries
		initialize();
	}, []);

	const initialize = async () => {
		try {
			setNetworkRequest(true);
            const urls = [ '/industries/active', '/client/profile'];
            const response = await genericController.performGetRequests(urls);
            const { 0: industries, 1: profile } = response;

            //	check if the request to fetch indstries doesn't fail before setting values to display
            if(industries && industries.data){
				setIndustryLoading(false);
                setIndustryOptions(industries.data.map( industry => ({label: industry.name, value: industry.id})));
            }

			if(profile && profile.data){
				const selectedIndustries = profile.data.JobIndustries.map(industry => ({label: industry.name, value : industry.id}));
				const gender = {label: profile.data.sex === "M" ? "Male" : 'Female', value: profile.data.sex};

				setValue('fname', profile.data.fname);
				setValue('lname', profile.data.lname);
				setValue('phone', profile.data.phone);
				setValue('company_name', profile.data.EmployerInfo.company_name);
				setValue('address', profile.data.EmployerInfo.address);
				setValue('company_email', profile.data.EmployerInfo.company_email);
				setValue('sex', gender);
				setValue('industry', selectedIndustries);
			}
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const onSubmit = async (data) => {
		try {
			setNetworkRequest(true);
			const response = await clientController.updateEmployer(data);

			if(response && response.status === 200){
				updateJWT(response);
			}
			setNetworkRequest(false);
			toast.info("Profile updated successfully");
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return onSubmit(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	};

	return (
		<>
			<MyContainer $padding_y="30px" className="container">
				<p className="display-6 ms-3">Edit Profile</p>
				<Form className="bg-light p-4 rounded-4 border border-2">
					<h4>User Info</h4>
					<Row className="mb-3">
						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="firstName"
						>
							<Form.Label>Firstname</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Firstname..."
								{...register("fname")}
							/>
							<ErrorMessage source={errors.fname} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="lastname"
						>
							<Form.Label>Lastname</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Lastname..."
								{...register("lname")}
							/>
							<ErrorMessage source={errors.lname} />
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="phoneNumber"
						>
							<Form.Label>Phone number</Form.Label>
							<Form.Control
								type="tel"
								placeholder="Phone number..."
								{...register("phone")}
							/>
							<ErrorMessage source={errors.phone} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="gender"
						>
							<Form.Label>Gender</Form.Label>
							<Controller
								name="sex"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										required
										placeholder="Gender..."
										{...register("sex")}
										options={genderOptions}
										onChange={(val) => onChange(val)}
										value={value}
									/>
								)}
							/>
							<ErrorMessage source={errors.sex} />
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="companyName"
						>
							<Form.Label>Company Name</Form.Label>
							<Form.Control
								type="tel"
								placeholder="Company Name..."
								{...register("company_name")}
							/>
							<ErrorMessage source={errors.company_name} />
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="companyAddress"
						>
							<Form.Label>Company Address</Form.Label>
							<Form.Control
								type="tel"
								placeholder="Company Address..."
								{...register("address")}
							/>
							<ErrorMessage source={errors.address} />
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="companyMail"
						>
							<Form.Label>Company Email</Form.Label>
							<Form.Control
								type="tel"
								placeholder="Company Email..."
								{...register("company_email")}
							/>
							<ErrorMessage source={errors.company_email} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="industry"
						>
							<Form.Label>Industry</Form.Label>
							<Controller
								name="industry"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										isMulti
										placeholder="Industry..."
										{...register("industry")}
										options={industryOptions}
										isLoading={industryLoading}
										value={value}
										onChange={(val) => { return onChange(val) }}
									/>
								)}
							/>
							<ErrorMessage source={errors.industry} />
						</Form.Group>
					</Row>
					<div className="text-center">
						<Button variant="primary" type="submit" disabled={networkRequest} onClick={handleSubmit(onSubmit)} >
							{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
							{ !networkRequest && `Update Profile` }
						</Button>
					</div>
				</Form>
			</MyContainer>
		</>
	);
};

export default EmployerProfileEditPage;
