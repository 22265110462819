import httpService from "../axios/http-service";

const updateSeeker = async (data) => {
    let formData = new FormData();
    formData.append('lname', data.lname);
    formData.append('fname', data.fname);
    formData.append('phone', data.phone);
    formData.append('sex', data.sex.value);
    formData.append('experience', data.experience.value);
    formData.append('highest_qualification', data.highest_qualification.value);
    formData.append('preferred_location', data.preferred_location.value);
    
    // attach cv if available
    formData.append('cv', data.cv[0]);
    
    /*  MAKE SHIFT
        append data 0 to industries field in case user only selects one industry to make it a valid array to bypass yup schema validation on the server side
    */
    formData.append('industries', 0);
    // attach array of industries
    for (var i = 0; i < data.industry.length; i++) {
        formData.append('industries', data.industry[i].value);
    }

    formData.append('industry', formData.getAll('industries'));
    return await httpService.put('/client/update', formData);
}

const registerSeeker = async (data, otpData) => {
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if (key !== 'industry') {
            formData.append(key, data[key]);
        };
    });
    
    // attach cv if available
    formData.append('cv', data.cv[0]);
    // attach otp
    formData.append('otp', otpData.otp);
    
    /*  MAKE SHIFT
        append data 0 to industries field in case user only selects one industry to make it a valid array to bypass yup schema validation on the server side
    */
    formData.append('industries', 0);
    // attach array of industries
    for (var i = 0; i < data.industry.length; i++) {
        formData.append('industries', data.industry[i].value);
    }

    formData.append('industry', formData.getAll('industries'))
    formData.append('termsOfService', true);

    return await httpService.post('/client/register/seeker', formData);
}

const registerEmployer = async (data, otpData) => {
    data.termsOfService = true;
    // attach otp
    data.otp = otpData.otp;
    const industryIds = data.industries.map(industry => industry?.value);
    data.industries = industryIds;
    return await httpService.post('/client/register/employer', data);
}

const updateEmployer = async (data) => {
    const industryIds = data.industry.map(industry => industry?.value);
    data.industries = industryIds;
    data.sex = data.sex.value;
    return await httpService.put('/client/update', data);
}

const changeStatus = async (data) => {
    return await httpService.put(`/client/status`, data);
}

const downloadMyCV = async () => {
    return await httpService.download(`/client/cv`);
}

const staffDownloadClientCV = async (id) => {
    return await httpService.download(`/client/cv/${id}`);
}

const updatePassword = async (data) => {
    return await httpService.put('/client/update-pw', data);
}

const resetPassword = async (data) => {
    return await httpService.put('/client/reset-pw', data);
}

const findAllByType = async (reqBody, pageSpan) => {
    return await httpService.post(`/client/list/${pageSpan}`, reqBody);
}

const findById = async (id) => {
    return await httpService.get(`/client/search/${id}`);
}

const findMyJobs = async (data) => {
    return await httpService.get('/client/my-jobs');
}

const findMyIndustries = async () => {
    return await httpService.get('/client/my-industries');
}

const removeMyIndustry = async (industryId) => {
    return await httpService.get(`/client/remove-industry/${industryId}`);
}

export default {
    updateSeeker,
    registerSeeker,
    registerEmployer,
    updateEmployer,
    changeStatus,
    downloadMyCV,
    staffDownloadClientCV,
    updatePassword,
    resetPassword,
    findById,
    findAllByType,
    findMyJobs,
    findMyIndustries,
    removeMyIndustry,
}