import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "../Assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Showcase from "../Components/Showcase/Showcase";
import { useAuth } from "../app-context/auth-user-context";
import WordSpan from "../Components/WordSpan";
import ErrorMessage from "../Components/ErrorMessage";
import handleErrMsg from '../Utils/error-handler';
import { ThreeDotLoading } from "../Components/react-loading-indicators/Indicator";

const StaffLogin = () => {
	const navigate = useNavigate();

	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const { staffLogin, authUser } = useAuth();
	const user = authUser();

	const schema = yup.object().shape({
		email: yup
			.string()
			.email("A valid email format is required")
			.required("Email is required"),
		pw: yup
			.string()
			.min(6, "Password must be a min of 6 characters!")
			.required("Input correct password"),
	});

	// Yup Integration with "react-hook-form"
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data) => {
		try {
			setIsLoggingIn(true);
			await staffLogin(data);
			setIsLoggingIn(false);
			navigate('/dashboard');
		} catch (ex) {
			setIsLoggingIn(false);
			toast.error(handleErrMsg(ex).msg);
		}
	};

    useEffect( () => {
		if(user) {
			navigate('/');
		}
    }, []);

	return (
		<>
			<Showcase height={"80vh"} align={"center"} justify={"center"}>
				<div className="align-self-center justify-self-center">
					<div
						className="container mx-auto"
						// style={{ height: "500px" }}
					>
						<main
							className="form-signin m-auto"
							style={{ minWidth: "320px", maxWidth: "350px" }}
						>
							<Form className="text-center text-dark">
								<img
									className="mb-4"
									src={logo}
									alt=""
									width="90"
									height="120"
								/>
								<h1 className="h3 mb-3 fw-normal text-white">
									<WordSpan>Staff</WordSpan> Sign In Page 
								</h1>

								<div className="form-floating mb-3">
									<input
										type="email"
										className="form-control rounded-bottom-0 mb-1"
										id="floatingInput"
										placeholder="name@example.com"
										{...register("email")}
									/>
									<label htmlFor="floatingInput">Email address</label>
									<ErrorMessage source={errors.email} />
								</div>
								<div className="form-floating mb-3">
									<input
										type="password"
										className="form-control rounded-top-0 mb-1"
										id="floatingPassword"
										placeholder="Password"
										{...register("pw")}
									/>
									<label htmlFor="floatingPassword">Password</label>
									<ErrorMessage source={errors.pw} />
								</div>
								<button
									className="btn btn-outline-secondary w-100 my-2 py-2"
									type="submit"
									disabled={isLoggingIn}
									onClick={handleSubmit(onSubmit)}
								>
									{ isLoggingIn && <ThreeDotLoading color="#f78419" size="medium" text='please wait' textColor="#f78419" /> }
									{!isLoggingIn && `Sign In` }
								</button>
								<Link
									to={`/staff/pwreset`}
									className="text-white"
									rel="noopener noreferrer"
								>
									Forgot password?
								</Link>
								<p className="mt-5 mb-3 text-white">&copy; 2024</p>
							</Form>
						</main>
					</div>
				</div>
			</Showcase>
		</>
	);
};

export default StaffLogin;
