import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { toast } from "react-toastify";

import ErrorMessage from "../../ErrorMessage";
import schema from "../../../Utils/yup-schema-validators/client-schema";
import { useAuth } from "../../../app-context/auth-user-context";
import genericController from "../../../controller/generic-controller";
import handleErrMsg from "../../../Utils/error-handler";
import { ThreeDotLoading } from "../../react-loading-indicators/Indicator";

const EmployerForm = ({ formData, setFormData, setPage }) => {

    const { handleRefresh, logout } = useAuth();

	const [industryOptions, setIndustryOptions] = useState([]);

	const [industryLoading, setIndustryLoading] = useState(true);

	// for otp, disable button to avoid sending twice
	const [networkRequest, setNetworkRequest] = useState(false);

	// Yup Integration with "react-hook-form"
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema.employerSchema),
		defaultValues: {
			fname: formData.fname,
			lname: formData.lname,
			email: formData.email,
			phone: formData.phone,
			company_name: formData.company_name,
			address: formData.address,
			company_email: formData.company_email,
		},
	});

    useEffect( () => {
		initialize();
    }, []);

	const initialize = async () => {
		try {
            const urls = [ '/industries/active'];
            const response = await genericController.performGetRequests(urls);
            const { 0: industries } = response;

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(industries && industries.data){
				setIndustryLoading(false);
                setIndustryOptions(industries.data.map( industry => ({label: industry.name, value: industry.id})));
            }
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const onSubmit = async (data) => {
		try {
			setNetworkRequest(true);
			// attach acc_type
			data.acc_type = 'E';
			setFormData(data);
			toast.info(`sending OTP to ${data.email}.`);
			// send otp to email
			await genericController.requestOTP(data.email);
			setPage(2);
			toast.info(`OTP sent to ${data.email}. If not found in your inbox, please check you spam`);
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return onSubmit(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	};

	return (
		<Form className="bg-light p-4 rounded-4 border border-2">
			<h3>Employer Info</h3>

			<Row className="mb-3">
				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="firstName"
				>
					<Form.Label>Firstname</Form.Label>
					<Form.Control
						required
						type="text"
						placeholder="Firstname"
						{...register("fname")}
					/>
					<ErrorMessage source={errors.fname} />
				</Form.Group>

				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="lastName"
				>
					<Form.Label>Lastname</Form.Label>
					<Form.Control
						required
						type="text"
						placeholder="Lastname"
						{...register("lname")}
					/>
					<ErrorMessage source={errors.lname} />
				</Form.Group>

				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="password"
				>
					<Form.Label>Password</Form.Label>
					<Form.Control
						required
						type="password"
						placeholder="Password"
						{...register("pw")}
					/>
					<ErrorMessage source={errors.pw} />
				</Form.Group>

				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="confirmPassword"
				>
					<Form.Label>Confirm Password</Form.Label>
					<Form.Control
						required
						type="password"
						placeholder="Confirm Password"
						{...register("confirmPassword")}
					/>
					<ErrorMessage source={errors.confirmPassword} />
				</Form.Group>

				<Form.Group className="my-2 my-sm-3" as={Col} sm="6" controlId="email">
					<Form.Label>Email</Form.Label>
					<Form.Control
						required
						type="email"
						placeholder="name@mail.com"
						{...register("email")}
					/>
					<ErrorMessage source={errors.email} />
				</Form.Group>

				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="gender"
				>
					<Form.Label>Gender</Form.Label>
					<Form.Select
						required
						aria-label="Default select example"
						placeholder="Select..."
						{...register("sex")}
					>
						<option>Select...</option>
						<option value="M">Male</option>
						<option value="F">Female</option>
					</Form.Select>
					<ErrorMessage source={errors.sex} />
				</Form.Group>

				<Form.Group className="my-2" as={Col} sm="6" controlId="phoneNumber">
					<Form.Label>Phone number</Form.Label>
					<Form.Control
						required
						type="tel"
						placeholder="07012345678"
						{...register("phone")}
					/>
					<ErrorMessage source={errors.phone} />
				</Form.Group>

				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="companyName"
				>
					<Form.Label>Company Name</Form.Label>
					<Form.Control
						required
						type="text"
						placeholder="Company Name"
						{...register("company_name")}
					/>
					<ErrorMessage source={errors.company_name} />
				</Form.Group>

				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="position"
				>
					<Form.Label>Address</Form.Label>
					<Form.Control
						required
						type="text"
						placeholder="Address..."
						{...register("address")}
					/>
					<ErrorMessage source={errors.address} />
				</Form.Group>

				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="industries"
				>
					<Form.Label>Industry</Form.Label>
					<Controller
						name="industries"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								required
								isMulti
								placeholder="Industries..."
								{...register("industries")}
								// value={industryOptions.find((e) => e.value === value)}
								options={industryOptions}
								isLoading={industryLoading}
								onChange={(val) => onChange(val)}
							/>
						)}
					/>
					<ErrorMessage source={errors.industries} />
				</Form.Group>

				<Form.Group
					className="my-2 my-sm-3"
					as={Col}
					sm="6"
					controlId="company email"
				>
					<Form.Label>Company Email</Form.Label>
					<Form.Control
						required
						type="text"
						placeholder="Company Email"
						{...register("company_email")}
					/>
					<ErrorMessage source={errors.company_email} />
				</Form.Group>
			</Row>
			<div className="text-center">
				<Button
					variant="primary"
					type="submit"
					disabled = {networkRequest}
					onClick={handleSubmit(onSubmit)}
				>
					{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" text='please wait' textColor="#ffffff" /> }
					{!networkRequest && `Create My Account` }
				</Button>
			</div>
		</Form>
	);
};

export default EmployerForm;
