import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Select from "react-select";

import ErrorMessage from "../../ErrorMessage";
import schema from "../../../Utils/yup-schema-validators/client-schema";
import genericController from "../../../controller/generic-controller";
import { useAuth } from "../../../app-context/auth-user-context";
import handleErrMsg from "../../../Utils/error-handler";
import { ThreeDotLoading } from "../../react-loading-indicators/Indicator";

const SeekerForm = ({ formData, setFormData, setPage }) => {

    const { handleRefresh, logout } = useAuth();

	const [industryOptions, setIndustryOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [experienceLevelOptions, setExperienceLevelOptions] = useState([]);
	const [highestQualificationOptions, setHighestQualificationsOptions] = useState([]);

	const [locationLoading, setLocationLoading] = useState(true);
	const [industryLoading, setIndustryLoading] = useState(true);
	const [experienceLoading, setExperienceLoading] = useState(true);
	const [qualificationLoading, setQualificationLoading] = useState(true);

	// for otp, disable button to avoid sending twice
	const [networkRequest, setNetworkRequest] = useState(false);
	// Yup Integration with "react-hook-form"
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema.seekerSchema),
		defaultValues: {
			fname: formData.fname,
			lname: formData.lname,
			email: formData.email,
			phone: formData.phone,
		},
	});

    useEffect( () => {
		initialize();
    }, []);

	const initialize = async () => {
		try {
            const urls = [ '/locations/active', '/industries/active', '/experience/active', '/qualifications/active'];
            const response = await genericController.performGetRequests(urls);
            const { 0: locations, 1: industries, 2: experience, 3: qualifications } = response;

            //check if the request to fetch location doesn't fail before setting values to display
            if(locations && locations.data){
				setLocationLoading(false);
                setLocationOptions(locations.data.map( location => ({label: location.name, value: location.name})));
            }

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(industries && industries.data){
				setIndustryLoading(false);
                setIndustryOptions(industries.data.map( industry => ({label: industry.name, value: industry.id})));
            }

            //check if the request to fetch experience doesn't fail before setting values to display
            if(experience && experience.data){
				setExperienceLoading(false);
                setExperienceLevelOptions(experience.data.map( exp => ({label: exp.name, value: exp.name})));
            }

            //check if the request to fetch certificate/qualifications doesn't fail before setting values to display
            if(qualifications && qualifications.data){
				setQualificationLoading(false);
                setHighestQualificationsOptions(qualifications.data.map( qualification => ({label: qualification.name, value: qualification.name})));
            }
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const onSubmit = async (data) => {
		try {
			setNetworkRequest(true);
			// attach acc_type
			data.acc_type = 'S';
			setFormData(data);
			toast.info(`sending OTP to ${data.email}.`);
			// send otp to email
			await genericController.requestOTP(data.email);
			setPage(2);
			toast.info(`OTP sent to ${data.email}. If not found in your inbox, please check you spam`);
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return onSubmit(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	};

	return (
		<>
			<Form className="bg-light p-4 rounded-4 border border-2">
				<Row className="mb-3">
					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="firstName"
					>
						<Form.Label>Firstname</Form.Label>
						<Form.Control
							required
							type="text"
							placeholder="Firstname..."
							{...register("fname")}
						/>
						<ErrorMessage source={errors.fname} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="lastname"
					>
						<Form.Label>Lastname</Form.Label>
						<Form.Control
							required
							type="text"
							placeholder="Lastname..."
							{...register("lname")}
						/>
						<ErrorMessage source={errors.lname} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="password"
					>
						<Form.Label>Password</Form.Label>
						<Form.Control
							required
							type="password"
							placeholder="Password..."
							{...register("pw")}
						/>
						<ErrorMessage source={errors.pw} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="confirmPassword"
					>
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Confirm Password..."
							{...register("confirmPassword")}
						/>
						<ErrorMessage source={errors.confirmPassword} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="email"
					>
						<Form.Label>Email</Form.Label>
						<Form.Control
							required
							type="email"
							placeholder="name@mail.com"
							{...register("email")}
						/>
						<ErrorMessage source={errors.email} />
					</Form.Group>

					<Form.Group className="my-2" as={Col} sm="6" controlId="phoneNumber">
						<Form.Label>Phone number</Form.Label>
						<Form.Control
							type="tel"
							placeholder="Phone number..."
							{...register("phone")}
						/>
						<ErrorMessage source={errors.phone} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="gender"
					>
						<Form.Label>Gender</Form.Label>
						<Form.Select
							required
							aria-label="Default select example"
							placeholder="Select..."
							{...register("sex")}
						>
							<option>Select...</option>
							<option value="M">Male</option>
							<option value="F">Female</option>
						</Form.Select>
						<ErrorMessage source={errors.sex} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="experience"
					>
						<Form.Label>Years of Experience</Form.Label>
						<Controller
							name="experience"
							control={control}
							render={({ field: { onChange } }) => (
								<Select
									required
									placeholder="Years of experience..."
									{...register("experience")}
									options={experienceLevelOptions}
									onChange={(val) => onChange(val.value)}
									isLoading={experienceLoading}
								/>
							)}
						/>
						<ErrorMessage source={errors.experience} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="qualification"
					>
						<Form.Label>Higest Qualification</Form.Label>
						<Controller
							name="highest_qualification"
							control={control}
							render={({ field: { onChange } }) => (
								<Select
									required
									placeholder="Highest Qualification..."
									{...register("highest_qualification")}
									options={highestQualificationOptions}
									isLoading={qualificationLoading}
									onChange={(val) => onChange(val.value)}
								/>
							)}
						/>
						<ErrorMessage source={errors.highest_qualification} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="industry"
					>
						<Form.Label>Industry</Form.Label>
						<Controller
							name="industry"
							control={control}
							render={({ field: { onChange } }) => (
								<Select
									isMulti
									placeholder="Industry..."
									{...register("industry")}
									options={industryOptions}
									isLoading={industryLoading}
									onChange={(val) => { return onChange(val) }}
								/>
							)}
						/>
						<ErrorMessage source={errors.industry} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						controlId="preferredLocation"
					>
						<Form.Label>Preferred Location</Form.Label>
						<Controller
							name="preferred_location"
							control={control}
							render={({ field: { onChange } }) => (
								<Select
									required
									placeholder="Location..."
									{...register("preferred_location")}
									onChange={(val) => onChange(val.value)}
									isLoading={locationLoading}
									options={locationOptions}
								/>
							)}
						/>
						<ErrorMessage source={errors.preferred_location} />
					</Form.Group>

					<Form.Group
						as={Col}
						sm="6"
						controlId="formFile"
						className="my-2 my-sm-3"
					>
						<Form.Label>Upload a CV</Form.Label>
						<Form.Control
							type="file"
							accept=".pdf,.doc,.docx"
							{...register("cv")}
						/>
						<ErrorMessage source={errors.cv} />
					</Form.Group>
				</Row>
				<div className="text-center">
					<Button
						variant="primary"
						type="submit"
						disabled = {networkRequest}
						onClick={handleSubmit(onSubmit)}
					>
						{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" text='please wait' textColor="#ffffff" /> }
						{!networkRequest && `Create My Account` }
					</Button>
				</div>
			</Form>
		</>
	);
};

export default SeekerForm;
