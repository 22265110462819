import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import jobsController from "../../controller/jobs-controller";
import { ThreeDotLoading } from "../react-loading-indicators/Indicator";

import Editor from "../quill/quill-editor";
import Quill from 'quill';

const Delta = Quill.import('delta');

const PostJobPreview = ({ setPage, page, formData, setFormData }) => {
	const navigate = useNavigate();

	const [networkRequest, setNetworkREquest] = useState(false);

	// Use a ref to access the quill instance directly
	const quillRef = useRef();

    const { authUser, handleRefresh, logout } = useAuth();
    const user = authUser();

    useEffect( () => {
		const quillData = formData.desc;
		let content = new Delta();
		quillData.forEach(element => {
			content.insert(element.insert, element.attributes)
		});
		quillRef.current.setContents(content);
    }, []);
	
	const onSubmit = async () => {		
		await postJob();
	};

	const postJob = async () => {
		try {
			setNetworkREquest(true);
			await jobsController.postJob(user, formData);
			toast.info("Post successful");
			setNetworkREquest(false);
			navigate("/dashboard");
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return postJob();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkREquest(false);
		}
	}

	// Yup Integration with "react-hook-form"
	const { handleSubmit } = useForm();

	return (
		<div className="bg-light p-4 rounded-4 border border-2">
			<h4 className="text-center fw-light">Job Preview</h4>

			<div className="row mb-4">
				<div className="col-12 col-sm-9 align-self-center my-3 py-2">
					<h4 className="fw-bold"> {formData.title} </h4>

					<div className="mb-3">
						<Badge className="bg-success-subtle text-muted me-3">
							<span>{formData.work_type}</span>
						</Badge>

						<Badge className="bg-warning-subtle text-muted">
							<span>{formData.location}</span>
						</Badge>
					</div>
					
					<div className="d-flex gap-2">
						Salary Range:{" "}
						<div className={formData.confidential === true ? 'text-danger' : 'text-success' + " fw-bolder"} style={{fontSize:"16px"}}>
							{formData.confidential === true ? "Confidential" : formData.min_salary.toLocaleString('en-US', { style: 'currency', currency: 'NGN' }) 
							+ ' up to ' + formData.max_salary.toLocaleString('en-US', { style: 'currency', currency: 'NGN' }) }
						</div>
					</div>
				</div>
			</div>

			<hr className="mb-5" />

			<div className="mb-3">
				<h4>Job Summary</h4>
				<p>
					{formData.summary}
				</p>
			</div>

			<hr className="mb-3" />

			<div className="mb-3">
				<h4>Job Description</h4>
				<Editor ref={quillRef} readOnly={true} withToolBar={false} />
			</div>

			<hr className="mb-3" />

			<div className="text-center">
				<Button
					variant="primary"
					onClick={() => {
						setPage(--page);
					}}
					className="mx-3"
				>
					Prev
				</Button>
				<Button
					variant="primary"
					type="submit"
					disabled={networkRequest}
					onClick={handleSubmit(onSubmit)}
				>
					{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" text='please wait' textColor="#ffffff" /> }
					{!networkRequest && `Post Job` }
				</Button>
			</div>
		</div>
	);
};

export default PostJobPreview;
