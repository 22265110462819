import React, { useEffect, useRef, useState } from "react";
import { Button, Row, Col, Badge, Table } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import { FaLocationDot, FaPeopleGroup } from "react-icons/fa6";
import { toast } from "react-toastify";

import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import WordSpan from "../../Components/WordSpan";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";

import Editor from "../../Components/quill/quill-editor";
import Quill from 'quill';
import jobsController from "../../controller/jobs-controller";
import ConfirmDialogComp from "../../Components/ConfirmDialogComp";
import EllipsisText from "../../Utils/EllipsisText";

const Delta = Quill.import('delta');

const FlaggedJobDescription = () => {
	const navigate = useNavigate();
	const {pathname} = useLocation();

    const { handleRefresh, logout } = useAuth();
	
	const [networkRequest, setNetworkRequest] = useState(true);
	const [jobId, setJobId] = useState();
	const [job, setJob] = useState({});
	const [applicants, setApplicants] = useState([]);
	const [reporters, setReporters] = useState([]);
	const [posterLink, setPosterLink] = useState('');
	const [posterName, setPosterName] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");
    const [confirmationReason, setConfirmationReason] = useState("");

	// Use a ref to access the quill instance directly
	const quillRef = useRef();

    useEffect( () => {
		const pathArr = pathname.split("/");
		setJobId(pathArr[pathArr.length - 2]);
		if(jobId) {
			initialize();
		}
    }, [jobId]);

	const initialize = async () => {
		try {
            const response = await jobsController.getFlaggedJobById(jobId);

            //check if the request to fetch active staff doesn't fail before setting values to display
            if(response && response.data){
				setNetworkRequest(true);
				setJob(response.data);
				setApplicants(response.data.applicant);
				if(response.data.Staffs.length > 0){
					const { Staffs } = response.data;
					// posted by staff
					const initials = Array.from(Staffs[0].fname)[0] + Array.from(Staffs[0].lname)[0];
					setPosterLink(`/dashboard/all-staff/${initials}/${Staffs[0].id}`);
					setPosterName(`${Staffs[0].fname} ${Staffs[0].lname}`);
				}

                setReporters(response.data.FlaggedJobs)
				if(response.data.Clients.length > 0){
					const { Clients } = response.data;
					// posted by Employer
					const initials = Array.from(Clients[0].fname)[0] + Array.from(Clients[0].lname)[0];
					setPosterLink(`/dashboard/employers/${Clients[0].id}/${initials}`);
					setPosterName(`${Clients[0].fname} ${Clients[0].lname}`);
				}

				const quillData = JSON.parse(response.data.desc);
                // const quillData = response.data.desc;
				let content = new Delta();
				quillData.forEach(element => {
					content.insert(element.insert, element.attributes)
				});
				quillRef.current.setContents(content);
				setNetworkRequest(false);
            }
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				// Incase of 401 Unauthorized, navigate to 404
				if(error.response?.status === 401){
					navigate('/404');
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	};

    const handleOpenModal = (reason) => {
		let msg = '';
		switch (reason) {
			case 'state':
				if(job.state === 1){
					return toast.info(`Can't suspend a closed job`);
				}
				if(job.state === 0){
					msg = `Suspend ${job.title} ?`;
				}else {
					msg = `Activate ${job.title} ?`;
				}
				break;
			case 'clear':
				if (reporters.length > 0) {
					msg = 'Clear all flags? Caution, Action cannot be undone';
				}else {
					return;
				}
				break;
		}
		setConfirmationReason(reason);
        setDisplayMsg(msg);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

	const handleConfirmAction = async () => {
        setShowModal(false);
		switch (confirmationReason) {
			case 'state':
				changeJobState();
				break;
			case 'clear':
				clearFlags();
				break;
		}
	}

	const changeJobState = async () => {
		try {
			setNetworkRequest(true);
			const reqBody = {
				id: job.id,
				state: job.state === 0 ? 2 : 0
			};
			await jobsController.staffChangeState(reqBody);
			const updated = {...job};
			updated.state = reqBody.state;
			setJob(updated);
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return changeJobState();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	}

	const clearFlags = async () => {
		try {
			const response = await jobsController.clearJobFlags(job.id);
			if(response.status === 200){
				setReporters([]);
				toast.info('Flags cleared successfully');
			}
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return clearFlags();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const buildApplicantRow = (applicant, i) => {
		const initials = Array.from(applicant.fname)[0] + Array.from(applicant.lname)[0];
		return <tr key={i}>
			<td>{i + 1}</td>
			<td> {applicant.fname} </td>
			<td> {applicant.lname} </td>
			<td> {applicant.sex} </td>
			<td> {applicant.email} </td>
			<td> {applicant.phone} </td>
			<td>
				<Link to={`/dashboard/seekers/${applicant.id}/${initials}`}>View</Link>
			</td>
		</tr>
	};

	const buildReporterRow = (reporter, i) => {
        const { id, fname, lname, acc_type } = reporter.Client;
		const initials = Array.from(fname)[0] + Array.from(lname)[0];
        let link = '';
        if(acc_type === 'E'){
            link = `/dashboard/employers/${id}/${initials}`;
        }else {
            link = `/dashboard/seekers/${id}/${initials}`;
        }
		return <tr key={i}>
			<td>{i + 1}</td>
			<td> {fname} </td>
			<td> {lname} </td>
			<td> <EllipsisText message={reporter.reason}/> </td>
			<td> {format(reporter.createdAt, "MM/dd/yyyy")} </td>
			<td>
				<Link to={link}>View</Link>
			</td>
		</tr>
	};

    const buildApplicantRows = applicants.map((applicant, i) => { return buildApplicantRow(applicant, i) });

    const buildReporterRows = reporters.map((reporter, i) => { return buildReporterRow(reporter, i) });

	return (
		<>
			<MyContainer $padding_y="20px">
				<div className="container">
					<div className="mb-4" id="head">
						<h2>
							<WordSpan> {job?.title} </WordSpan>
						</h2>
						<Row className="mb-2">
							<Col>
                                <div className="d-flex gap-3 align-items-center text-muted">
                                    { job.createdAt && <small className="">{formatDistanceToNow(job.createdAt, {addSuffix: true})}</small>}
                                    <span>
                                        <FaLocationDot /> {job?.JobLocation?.name}
                                    </span>
                                    <br />
                                    <span> {job?.work_type === 1 ? 'Full time' : 'Part Time'} </span>
                                </div>
							</Col>
						</Row>
                        <Row className="mb-3">
							<Col>
                                <div className="d-flex gap-3 align-items-center fw-bold">
                                    Posted By: <Link to={posterLink}> {posterName} </Link>
                                </div>
							</Col>
						</Row>
                        <Row className="mb-3">
							<Col>
                                <div className="d-flex gap-3 align-items-center">
                                    Total Flags: <span className="text-danger fw-bold">{reporters.length}</span>
                                </div>
							</Col>
						</Row>
						
						<Row>
							<Col xs="12" sm="9">
								{/* <Badge className="bg-danger-subtle text-muted p-2">Suspended</Badge> */}
								<h2 className={`${job.state > 0 ? 'text-danger' : 'text-primary'}`}>
									{job.state === 0 ? 'Active' : job.state === 1 ? 'Closed' : 'Suspended'}
								</h2>
							</Col>
							<Col xs="12" sm="3" className="text-sm-end my-2 my-sm-0">
								<Button variant={`${job.state === 0 ? 'danger' : 'success'}`} onClick={() => handleOpenModal('state')} className="w-75" disabled={networkRequest}>
									{"  "}
									{job.state > 1 ? "Activate" : "Suspend"}
								</Button>
							</Col>
						</Row>
					</div>
					<hr />
					<div id="body">
						<h4 className="py-1 mb-2 fw-bold">Job Summary</h4>
						<p className="fw-normal mb-3">
							{job?.summary}.
						</p>
						<hr />
						<h4 className="py-1 mb-2 fw-bold">Job Description</h4>
						<Editor ref={quillRef} readOnly={true} withToolBar={false} />
						<hr className="my-4" />
					</div>
				</div>
				<div className="container p-4 mt-3 border border-3 rounded">
					<h4>
						{" "}
						<FaPeopleGroup /> <WordSpan>Applicants</WordSpan>
					</h4>
					<Table
						className="bg-light border border-secondary-subtle"
						striped
						variant="light"
						responsive="sm"
					>
						<thead>
							<tr>
								<th>#</th>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Sex</th>
								<th>Email</th>
								<th>Phone Number</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{buildApplicantRows}
						</tbody>
					</Table>
				</div>
				<div className="container p-4 mt-3 border border-3 rounded">
					<h4>
						{" "}
						<FaPeopleGroup /> <WordSpan>Reported</WordSpan> By
					</h4>
					<Table
						className="bg-light border border-secondary-subtle"
						striped
						variant="light"
						responsive="sm"
					>
						<thead>
							<tr>
								<th>#</th>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Reason</th>
								<th>Date</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{buildReporterRows}
						</tbody>
					</Table>
                    <span className="btn-link" onClick={() => handleOpenModal('clear')}>clear all</span>
				</div>
                <ConfirmDialogComp show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmAction} message={displayMsg} />
			</MyContainer>
		</>
	);
};

export default FlaggedJobDescription;