import httpService from "../axios/http-service";

const register = async (data) => {
    return await httpService.post('/staff/register', data);
}

const changeStatus = async (staff_id, status) => {
    return await httpService.put('/staff/status', { status, staff_id });
}

const updateRoles = async (data) => {
    const roles = data.authorities.map( auth => auth.value );
    return await httpService.put('/staff/update-roles', { authorities: roles, staff_id: data.staff_id });
}

const updatePassword = async (data) => {
    return await httpService.put('/staff/update-pw', data);
}

const resetPassword = async (data) => {
    return await httpService.put('/staff/reset-pw', data);
}

const findById = async (id) => {
    return await httpService.get(`/staff/search/${id}`);
}

const findNonViewed = async (reqBody, pageSpan, idOffset) => {
    reqBody.idOffset = idOffset;
    return await httpService.post(`/jobs/staff/non-viewed/${pageSpan}`, reqBody);
}

const findAll = async () => {
    return await httpService.get('/staff/all');
}

const updateProfile = async (data) => {
    data.sex = data.sex.value;
    return await httpService.put('/staff/update', data);
}

const getAuthorities = async () => {
    return await httpService.get('/staff/auths');
};

const updateIndustryStatus = async (data) => {
    return await httpService.put('/industries/status', data);
};

const addIndustry = async (data) => {
    return await httpService.post('/industries/add', data);
};

const updateLocationStatus = async (data) => {
    return await httpService.put('/locations/status', data);
};

const addLocation = async (data) => {
    return await httpService.post('/locations/add', data);
};

const updateExperienceStatus = async (data) => {
    return await httpService.put('/experience/status', data);
};

const addExperience = async (data) => {
    return await httpService.post('/experience/add', data);
};

const updateQualificationStatus = async (data) => {
    return await httpService.put('/qualifications/status', data);
};

const addQualification = async (data) => {
    return await httpService.post('/qualifications/add', data);
};

const updateCurrentYear = async (data) => {
    return await httpService.post('/year/set', data);
};

const removeUnverifiedMail = async (email) => {
    return await httpService.get(`/staff/unverified-mails/remove/${email}`);
};

const clearUnverifiedMails = async (email) => {
    return await httpService.get(`/staff/unverified-mails/clear`);
};

const updateTermsAndAgreement = async (data) => {
    return await httpService.post('/terms/update', data);
};

export default {
    register,
    changeStatus,
    updateRoles,
    updatePassword,
    resetPassword,
    findAll,
    findById,
    findNonViewed,
    updateProfile,
    getAuthorities,
    updateIndustryStatus,
    addIndustry,
    updateLocationStatus,
    addLocation,
    updateExperienceStatus,
    addExperience,
    updateQualificationStatus,
    addQualification,
    updateCurrentYear,
    removeUnverifiedMail,
    clearUnverifiedMails,
    updateTermsAndAgreement,
}