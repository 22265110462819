import * as yup from "yup";

let email_regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const employerSchema = yup.object().shape({
	fname: yup.string().required("First Name is required!"),
	lname: yup.string().required("Last Name is required!"),
	email: yup
	.string()
	.email()
	.matches(email_regx, 'A valid email format example@mail.com is required').required("Email is required"),
	phone: yup
		.string()
		.test(
			"len",
			"Phone number must be 11 characters",
			(val) => val.length === 11
		)
		.required("Phone number is required"),
	sex: yup.string().max(1, "Gender is required").required("Gender is required!"),

	// age: yup.string.required("Enter your phone number"),
	pw: yup.string().min(6).required("Password must be a min of 6 characters!"),

	industries: yup
		.array()
		.min(1, "At least one industry is required")
		.of(
			yup.object().shape({
				value: yup.string().required(),
				label: yup.string().required(),
			})
		)
		// .array()
		// .oneOf(getArray(industryOptions), "Select from the options!")
		.required("Industry is required"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("pw"), null])
		.required("Password Not a match!"),

	// Employer Specific
	company_name: yup.string().required("Enter your Company name!"),
	address: yup.string().required("Enter address of company"),
	company_email: yup
		.string()
		.email()
		.matches(email_regx, 'A valid email format example@mail.com is required').required("Email is required"),
});

const seekerSchema = yup.object().shape({
	fname: yup.string().required("First name is required!"),
	lname: yup.string().required("Last name is required!"),
    email: yup
        .string()
        .email()
        .matches(email_regx, 'A valid email format example@mail.com is required').required("Email is required"),
	phone: yup
		.string()
		.test(
			"len",
			"Phone number must be 11 characters",
			(val) => val.length === 11
		)
		.required("Phone number is required"),
	sex: yup.string().max(1, "Gender is required").required("Gender is required!"),

	// age: yup.string.required("Enter your phone number"),
	pw: yup.string().min(6).required("Password must be a min of 6 characters!"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("pw"), 'Password must match'])
		.required("Password Not a match!"),

	industry: yup.array().min(1, "At least one industry is required").required("Industry is required"),

	// ref for file validation: https://dev.to/olabisi09/file-validation-in-react-with-formik-and-yup-48e6
	cv: yup
		.mixed()
		.test("fileFormat", "Unsupported file format", (value) => {
			if (value.length > 0) {
				const supportedFormats = ["pdf", "doc", "docx"];
				return supportedFormats.includes(value['0'].name.split(".").pop());
			}
			return true;
		})
		.test("fileSize", "File size must not be more than 512KB", (value) => {
			if (value.length > 0) {
				return value['0'].size <= 0.5 * 1024 * 1024;
			}
			return true;
		})
		.test("required", "Please upload a CV", (value) => {
			return value.length > 0;
		}),

	// Seeker Specific
	experience: yup
		.string()
		.required("Years of experirence is required"),
	highest_qualification: yup
		.string()
		.required("Highest level of qualaification is required!"),
	// availability: yup.string().required("Your availability is required"),
    preferred_location: yup.string().required("Preferred location is required"),
});

const seekerUpdateSchema = yup.object().shape({
	fname: yup.string().required("First name is required!"),
	lname: yup.string().required("Last name is required!"),
	phone: yup
		.string()
		.test(
			"len",
			"Phone number must be 11 characters",
			(val) => val.length === 11
		)
		.required("Phone number is required"),
	sex:  yup.object().shape({
		label: yup.string().required('Invalid value'),
		value: yup.string().required('Invalid value')
	}).required("Gender is required!"),

	industry: yup.array().min(1, "At least one industry is required").required("Industry is required"),

	// ref for file validation: https://dev.to/olabisi09/file-validation-in-react-with-formik-and-yup-48e6
	cv: yup
		.mixed()
		.test("fileFormat", "Unsupported file format", (value) => {
			if (value.length > 0) {
			const supportedFormats = ["pdf", "doc", "docx"];
				return supportedFormats.includes(value['0'].name.split(".").pop());
			}
			return true;
		})
		.test("fileSize", "File size must not be more than 512KB", (value) => {
			if (value.length > 0) {
				return value['0'].size <= 0.5 * 1024 * 1024;
			}
			return true;
		}
	),

	// Seeker Specific
	experience: yup.object().shape({
		label: yup.string().required('Invalid value'),
		value: yup.string().required('Invalid value')
	}).required("Experience level is required"),
	
	highest_qualification: yup.object().shape({
		label: yup.string().required('Invalid value'),
		value: yup.string().required('Invalid value')
	}).required("Qualification is required"),

    preferred_location: yup.object().shape({
		label: yup.string().required('Invalid value'),
		value: yup.string().required('Invalid value')
	}).required("Preferred location is required"),

});

const employerUpdateSchema = yup.object().shape({
	fname: yup.string().required("First name is required!"),
	lname: yup.string().required("Last name is required!"),
	phone: yup
		.string()
		.test(
			"len",
			"Phone number must be 11 characters",
			(val) => val.length === 11
		).required("Phone number is required"),
	sex: yup.object().shape({
		label: yup.string().required('Invalid value'),
		value: yup.string().required('Invalid value')
	}).required("Gender is required!"),

	industry: yup.array().min(1, "At least one industry is required").required("Industry is required"),

	// Employer Specific
	company_name: yup.string().required("Enter your Company name!"),
	address: yup.string().required("Enter address of company"),
	company_email: yup
		.string()
		.email()
		.matches(email_regx, 'A valid email format example@mail.com is required').required("Email is required"),

});

export default {
	employerSchema,
	seekerSchema,
	seekerUpdateSchema,
	employerUpdateSchema,
};
