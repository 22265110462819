import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";

import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import ErrorMessage from "../../Components/ErrorMessage";
import staffController from "../../controller/staff-controller";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import genericController from "../../controller/generic-controller";
import ConfirmDialogComp from "../../Components/ConfirmDialogComp";

const ViewStaffPage = () => {
	const navigate = useNavigate();
	let location = useLocation();

	const schema = yup.object().shape({
		authorities: yup.array()
	});

	const {
		register,
        setValue,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const [staff, setStaff] = useState({});
	const [authsOptions, setAuthsOptions] = useState([]);
	const [authsLoading, setAuthsLoading] = useState(true);
	const [networkRequest, setNetworkREquest] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");

    const { handleRefresh, logout } = useAuth();

    useEffect( () => {
		const arr = location.pathname.split('/');
		const id = arr[arr.length - 1];
		if(id) {
			initialize(id);
		}
    }, []);

	const initialize = async (id) => {
		try {
            const urls = [ `/staff/profile/search/${id}`, '/staff/auths'];
            const response = await genericController.performGetRequests(urls);
            const { 0: profile, 1: authorities } = response;

            //	check if the request to fetch authorities doesn't fail before setting values to display
            if(profile && profile.data){
				setNetworkREquest(false);
				setStaff(profile.data);
				const appliedAuths = profile.data.Authorities?.map( auth => ( {label: auth.name, value: auth.code} ));
                setValue('fname', profile.data.fname);
                setValue('lname', profile.data.lname);
                setValue('phone', profile.data.phone);
                setValue('email', profile.data.email);
                setValue('creator', `${profile.data.creator.fname} ${profile.data.creator.lname}`);
                setValue('sex', profile.data.sex === 'M' ? "Male" : "Female");
                setValue('authorities', appliedAuths);
            }

            //	check if the request to fetch authorities doesn't fail before setting values to display
            if(authorities && authorities.data){
				setAuthsLoading(false);
                setAuthsOptions(authorities.data.map( auth => ({label: auth.name, value: auth.code})) );
            }
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize(id);
				}
				// Incase of 401 Unauthorized, navigate to 404
				if(error.response?.status === 401){
					navigate('/404')
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const update = async (data) => {
		try {
			setNetworkREquest(true);
			data.staff_id = staff.id;
			await staffController.updateRoles(data);
			setNetworkREquest(false);
			toast.info("Authorities successfully updated");
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return update(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkREquest(false);
		}
	};
    
    const handleCloseModal = () => setShowModal(false);

    const handleOpenModal = () => {
        setDisplayMsg(`${staff.status ? 'Deactivate' : "Activate"} ${staff.fname} ${staff.lname}?`);
        setShowModal(true);
    };
	
	const handleConfirmAction = async () => {
		setShowModal(false);
		try {
			setNetworkREquest(true);
			await staffController.changeStatus(staff.id, !staff.status);
			toast.info("Account successfully Updated");
			setStaff({...staff, status: !staff.status});
			setNetworkREquest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return handleConfirmAction();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkREquest(false);
		}
	};

	return (
		<>
			<MyContainer $padding_y="30px" className="container">
				<div className="bg-light p-4 rounded-4 border border-2">
					<div className="d-flex gap-3">
						<h4>User Info:</h4>
						<h4 className={staff.status ? 'text-success fw-bold' : 'text-danger fw-bold'}> {staff.status ? 'Active' : 'Deactivated'} </h4>
					</div>
					<Row className="mb-3">
						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="firstName"
						>
							<Form.Label>Firstname</Form.Label>
							<Form.Control type="text" {...register("fname")} disabled />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="lastname"
						>
							<Form.Label>Lastname</Form.Label>
							<Form.Control type="text" placeholder="Lastname..." {...register("lname")} disabled />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="email"
						>
							<Form.Label>Email</Form.Label>
							<Form.Control {...register("email")} disabled type="email" placeholder="name@mail.com" />
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="phoneNumber"
						>
							<Form.Label>Phone number</Form.Label>
							<Form.Control type="text" placeholder="Phone number..." {...register("phone")} disabled />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="gender"
						>
							<Form.Label>Gender</Form.Label>
							<Form.Control type="text" placeholder="Sex..." {...register("sex")} disabled/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="creator"
						>
							<Form.Label>Account Creator</Form.Label>
							<Form.Control type="text" placeholder="Sex..." {...register("creator")} disabled/>
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="authorities"
						>
							<Form.Label>Authorities</Form.Label>
							<Controller
								name="authorities"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										isMulti
										placeholder="Authorities..."
										{...register("authorities")}
										options={authsOptions}
										value={value}
										onChange={(val) => onChange(val)}
										isLoading={authsLoading}
									/>
								)}
							/>
							<ErrorMessage source={errors.authorities} />
						</Form.Group>
					</Row>
					<div className="text-center d-flex gap-3 justify-content-center">
						<Button
                            style={{ width: '10rem' }}
							variant={staff.status ? "danger" : 'primary'}
							type="submit"
							disabled={networkRequest}
							onClick={handleSubmit(handleOpenModal)}
						>
						{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" text='please wait' textColor="#ffffff" /> }
						{ staff.status && staff.status === true && 'Deactivate'}
						{ staff.status === false && 'Activate'}
						</Button>

                        <Button
                            style={{width: '10rem'}}
							variant="success"
							type="submit"
							disabled={networkRequest}
							onClick={handleSubmit(update)}
						>
						{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" text='please wait' textColor="#ffffff" /> }
						{!networkRequest && `Update` }
						</Button>
					</div>
				</div>

				<ConfirmDialogComp
					show={showModal}
					handleClose={handleCloseModal}
					handleConfirm={handleConfirmAction}
					message={displayMsg}
				/>
			</MyContainer>
		</>
	);
};

export default ViewStaffPage;
