import * as yup from "yup";
import { workTypeOptions } from "../../the-advancement-place";

const getArray = (option) => {
	return option.map((e) => e.value);
};

const schema = yup.object().shape({
	title: yup.string().required("Job title is required!"),

	industry: yup.string().required("Job Industry is required"),

	work_type: yup
		.string()
		.oneOf(getArray(workTypeOptions), "Select from the list!")
		.required("Select your required work type"),

	location: yup.string().required("Job Location is required"),

	min_qualification: yup
		.string()
		.required("Qualification is required"),

	experience: yup
		.string()
		.required("Years of experirence is required"),

	confidential: yup.boolean(),
	min_salary: yup.number().when("confidential", {
		is: false,
		then: () =>
			yup
				.number()
				.typeError("Please enter an amount")
				.max(
					yup.ref("max_salary"),
					"Must be less than Maximum salary"
				)
				.integer("Must be an integer")
				.positive("Must be a positive number"),
		// .required("Enter min. salary"),
		otherwise: () => yup.number().notRequired(),
	}),

	max_salary: yup.number().when("confidential", {
		is: false,
		then: () =>
			yup
				.number()
				.typeError("Please enter an amount")
				.integer("Must be an integer")
				.positive("Must be a positive number"),
		// .required("Enter max. salary"),
		otherwise: () => yup.number().nullable(),
	}),
	available_openings: yup
		.number("Number is required")
		.integer("Number is required")
		.positive("Unsupported format")
		.required("Your availability is required"),
	// preferred_location: yup.string().required("Preffered location is required"),
});

export default schema;
