import React, { useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { MyContainer } from '../Components/Styles/GlobalStyle.css'
import WordSpan from '../Components/WordSpan'
import ErrorMessage from '../Components/ErrorMessage'
import { ThreeDotLoading } from '../Components/react-loading-indicators/Indicator'
import handleErrMsg from '../Utils/error-handler';
import clientController from '../controller/client-controller';

const ClientPassReset = () => {
    const navigate = useNavigate();
    
    let email_regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const schema = yup.object().shape({
        email: yup.string().email()
            .matches(email_regx, 'A valid email format example@mail.com is required')
            .required("Email is required"),
        fname: yup.string().required("First name is required!"),
	    lname: yup.string().required("Last name is required!"),
    });

	const [networkRequest, setNetworkRequest] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        try {
			setNetworkRequest(true);
			let response = await clientController.resetPassword(data);
			setNetworkRequest(false);
			if(response && response.data){
				toast.info(response.data.message);
                reset();
                navigate('/login');
			}
		} catch (error) {
            toast.error(handleErrMsg(error).msg);
			setNetworkRequest(false);
		}
    };
    return (
        <>
            <MyContainer $padding_y="35px">
                <div className="container py-md-5 py-3">
                    <div className="text-center">
                        <h2 className="fw-bold">
                            Lost  
                            <WordSpan>
                                <span className="bungee-regular"> Password </span>
                            </WordSpan>
                            Reset
                        </h2>
                    <p>Forgotten your password? Enter your email address, first name and last name below to begin the reset process.</p>
                    </div>
                    <Form className="d-flex justify-content-center">
                        <div className="bg-light p-4 rounded-4 border border-2">
                            <Row style={{ width: "20rem" }}>
                                <Form.Group className="my-2 my-sm-3" controlId="email" >
                                    <Form.Label>
                                        Email Address
                                    </Form.Label>
                                    <Form.Control
                                        className='w-100'
                                        required
                                        type="email"
                                        placeholder="Email..."
                                        {...register("email")}
                                    />
                                    <ErrorMessage source={errors.email} />
                                </Form.Group>

                                <Form.Group className="my-2 my-sm-3" controlId="fname" >
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                            className='w-100'
                                            required
                                            type="text"
                                            placeholder="First Name..."
                                            {...register("fname")}
                                    />
                                    <ErrorMessage source={errors.fname} />
                                </Form.Group>

                                <Form.Group className="my-2 my-sm-3" controlId="lname" >
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        className='w-100'
                                        type="text"
                                        placeholder="First Name..."
                                        {...register("lname")}
                                    />
                                    <ErrorMessage source={errors.lname} />
                                </Form.Group>
                            </Row>
                            <div className="text-center">
                                <Button className='mt-3 w-75' variant="primary" type="submit" onClick={handleSubmit(onSubmit)} >
                                    { networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
                                    { !networkRequest && `Reset` }
                                </Button>
                            </div>

                        </div>
                    </Form>
                </div>
            </MyContainer>
        </>
    )
}

export default ClientPassReset;