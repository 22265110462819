import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import fileDownload from "js-file-download";

import ErrorMessage from "../../Components/ErrorMessage";
import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import genericController from "../../controller/generic-controller";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import clientSchema from "../../Utils/yup-schema-validators/client-schema";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import clientController from "../../controller/client-controller";

const SeekerProfileEditPage = () => {
	const navigate = useNavigate();

    const { handleRefresh, logout, authUser, updateJWT } = useAuth();
	const user = authUser();

	const [genderOptions] = useState([
		{label: "Male", value: "M"},
		{label: "Female", value: "F"}
	]);
	const [industryOptions, setIndustryOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [experienceLevelOptions, setExperienceLevelOptions] = useState([]);
	const [highestQualificationOptions, setHighestQualificationsOptions] = useState([]);

	const [networkRequest, setNetworkRequest] = useState(false);
	const [locationLoading, setLocationLoading] = useState(true);
	const [industryLoading, setIndustryLoading] = useState(true);
	const [experienceLoading, setExperienceLoading] = useState(true);
	const [qualificationLoading, setQualificationLoading] = useState(true);
	const [client, setClient] = useState({});

	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(clientSchema.seekerUpdateSchema),
	});

	useEffect( () => {
		if(user.accType === 'E'){
			navigate('/dashboard/employer/edit-profile');
			return;
		}
		// fetch client industries
		initialize();
	}, []);

	const initialize = async () => {
		try {
			setNetworkRequest(true);
            const urls = [ '/locations/active', '/industries/active', '/experience/active', '/qualifications/active', '/client/profile'];
            const response = await genericController.performGetRequests(urls);
            const { 0: locations, 1: industries, 2: experience, 3: qualifications, 4: profile } = response;

            //	check if the request to fetch location doesn't fail before setting values to display
            if(locations && locations.data){
				setLocationLoading(false);
                setLocationOptions(locations.data.map( location => ({label: location.name, value: location.name})));
            }

            //	check if the request to fetch indstries doesn't fail before setting values to display
            if(industries && industries.data){
				setIndustryLoading(false);
                setIndustryOptions(industries.data.map( industry => ({label: industry.name, value: industry.id})));
            }

            //	check if the request to fetch experience doesn't fail before setting values to display
            if(experience && experience.data){
				setExperienceLoading(false);
                setExperienceLevelOptions(experience.data.map( exp => ({label: exp.name, value: exp.name})));
            }

            //	check if the request to fetch certificate/qualifications doesn't fail before setting values to display
            if(qualifications && qualifications.data){
				setQualificationLoading(false);
                setHighestQualificationsOptions(qualifications.data.map( qualification => ({label: qualification.name, value: qualification.name})));
            }

			if(profile && profile.data){
				setClient(profile.data);
				const selectedIndustries = profile.data.JobIndustries.map(industry => ({label: industry.name, value : industry.id}));
				const currentExperience = {label: profile.data.SeekerInfo.years_of_experience, value: profile.data.SeekerInfo.years_of_experience};
				const highestQualification = {label: profile.data.SeekerInfo.highest_qualification, value: profile.data.SeekerInfo.highest_qualification};
				const prefferedLocation = {label: profile.data.SeekerInfo.preferred_location, value: profile.data.SeekerInfo.preferred_location};
				const gender = {label: profile.data.sex === "M" ? "Male" : 'Female', value: profile.data.sex};

				setValue('fname', profile.data.fname);
				setValue('lname', profile.data.lname);
				setValue('phone', profile.data.phone);
				setValue('sex', gender);
				setValue('experience', currentExperience);
				setValue('highest_qualification', highestQualification);
				setValue('preferred_location', prefferedLocation); 
				setValue('industry', selectedIndustries);
			}
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const onSubmit = async (data) => {
		try {
			setNetworkRequest(true);
			const response = await clientController.updateSeeker(data);

			if(response && response.status === 200){
				setClient(response.data);
				updateJWT(response);
			}
			setNetworkRequest(false);
			toast.info("Profile updated successfully");
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return onSubmit(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	};

	const download = async () => {
        try {
            const response = await clientController.downloadMyCV();
            if(response && response.data){
                fileDownload(response.data, `${client.fname}_${client.lname}_cv${client.SeekerInfo.cv_ext}`);
            }
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return download();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
        }
    }

	return (
		<>
			<MyContainer $padding_y="30px" className="container">
				<p className="display-6 ms-3">Edit Profile</p>
				<Form className="bg-light p-4 rounded-4 border border-2">
					<h4>User Info</h4>
					<Row className="mb-3">
						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="firstName"
						>
							<Form.Label>Firstname</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Firstname..."
								{...register("fname")}
							/>
							<ErrorMessage source={errors.fname} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="lastname"
						>
							<Form.Label>Lastname</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Lastname..."
								{...register("lname")}
							/>
							<ErrorMessage source={errors.lname} />
						</Form.Group>

						<Form.Group
							className="my-2"
							as={Col}
							sm="6"
							controlId="phoneNumber"
						>
							<Form.Label>Phone number</Form.Label>
							<Form.Control
								type="tel"
								placeholder="Phone number..."
								{...register("phone")}
							/>
							<ErrorMessage source={errors.phone} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="gender"
						>
							<Form.Label>Gender</Form.Label>
							<Controller
								name="sex"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										required
										placeholder="Gender..."
										{...register("sex")}
										options={genderOptions}
										onChange={(val) => onChange(val)}
										value={value}
									/>
								)}
							/>
							<ErrorMessage source={errors.sex} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="experience"
						>
							<Form.Label>Years of Experience</Form.Label>
							<Controller
								name="experience"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										required
										placeholder="Years of experience..."
										{...register("experience")}
										options={experienceLevelOptions}
										onChange={(val) => onChange(val)}
										value={value}
										isLoading={experienceLoading}
									/>
								)}
							/>
							<ErrorMessage source={errors.experience} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="qualification"
						>
							<Form.Label>Higest Qualification</Form.Label>
							<Controller
								name="highest_qualification"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										required
										placeholder="Highest Qualification..."
										{...register("highest_qualification")}
										options={highestQualificationOptions}
										isLoading={qualificationLoading}
										value={value}
										onChange={(val) => onChange(val)}
									/>
								)}
							/>
							<ErrorMessage source={errors.highest_qualification} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							sm="6"
							controlId="industry"
						>
							<Form.Label>Industry</Form.Label>
							<Controller
								name="industry"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										isMulti
										placeholder="Industry..."
										{...register("industry")}
										options={industryOptions}
										isLoading={industryLoading}
										value={value}
										onChange={(val) => { return onChange(val) }}
									/>
								)}
							/>
							<ErrorMessage source={errors.industry} />
						</Form.Group>

						<Form.Group
							className="my-2 my-sm-3"
							as={Col}
							controlId="preferredLocation"
						>
							<Form.Label>Preferred Location</Form.Label>
							<Controller
								name="preferred_location"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										required
										placeholder="Location..."
										{...register("preferred_location")}
										onChange={(val) => onChange(val)}
										value={value}
										isLoading={locationLoading}
										options={locationOptions}
									/>
								)}
							/>
							<ErrorMessage source={errors.preferred_location} />
						</Form.Group>

						<Form.Group
							as={Col}
							sm="6"
							controlId="formFile"
							className="my-2 my-sm-3"
						>
							<Form.Label>Upload a CV</Form.Label>
							<Form.Control
								type="file"
								accept=".pdf,.doc,.docx"
								{...register("cv")}
							/>
							<ErrorMessage source={errors.cv} />
						</Form.Group>

						<Form.Group
							as={Col}
							sm="6"
							className="my-2 my-sm-3 d-flex flex-column justify-content-end"
						>
                            <Form.Label className={`fw-bold`}>
                                { client.SeekerInfo && 
                                    client.SeekerInfo.cv === true 
                                        ? <Link className="text-primary" onClick={() => download()}>Download CV</Link> 
                                        : <span className="text-danger">No CV</span> 
                                }
                            </Form.Label>
						</Form.Group>
					</Row>
					<div className="text-center">
						<Button
							variant="primary"
							type="submit"
							disabled={networkRequest}
							onClick={handleSubmit(onSubmit)}
						>
							{ networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
							{ !networkRequest && `Update Profile` }
						</Button>
					</div>
				</Form>
			</MyContainer>
		</>
	);
};

export default SeekerProfileEditPage;
