import httpService from "../axios/http-service";

const search = async (reqBody, pageSpan) => {
    return await httpService.post(`/jobs/search/${pageSpan}`, reqBody);
}

const searchById = async (id) => {
    return await httpService.get(`/jobs/search/${id}`);
}

const recent = async (pageSize) => {
    return await httpService.get(`/jobs/${pageSize}`);
}

const postJob = async (authUser, data) => {
    if(data.confidential === true) {
        data.min_salary = 0;
        data.max_salary = 0;
    }
    if(data.work_type === "Full Time") {
        data.work_type = true;
    }else {
        data.work_type = false;
    }
    if(authUser?.accType === "E"){
        // employer job post
        await httpService.post('/jobs/employer/create', data);
    }else {
        // staff job post
        await httpService.post('/jobs/staff/create', data);
    }
}

const report = async (job) => {
    return await httpService.post('/jobs/report', job);
}

const apply = async (id) => {
    return await httpService.post(`/jobs/apply/${id}`);
}

const clearJobFlags = async (id) => {
    return await httpService.put(`/jobs/flags/clear/${id}`);
}

const getFlaggedJobs = async (reqBody, pageSpan) => {
    return await httpService.post(`/jobs/flagged-jobs/${pageSpan}`, reqBody);
}

const getFlaggedJobById = async (id) => {
    return await httpService.get(`/jobs/flagged-job/${id}`);
}

const staffSearch = async (reqBody, pageSpan, idOffset) => {
    reqBody.idOffset = idOffset;
    return await httpService.post(`/jobs/staff/search/${pageSpan}`, reqBody);
}

const staffMoreFilteredSearch = async (reqBody, pageSpan, idOffset) => {
    reqBody.idOffset = idOffset;
    return await httpService.post(`/jobs/staff/search/more-filter/${pageSpan}`, reqBody);
}

const staffChangeState = async (reqBody) => {
    return await httpService.post('/jobs/staff/state', reqBody);
}

const employerChangeState = async (reqBody) => {
    return await httpService.post('/jobs/client/state', reqBody);
}

export default {
    search,
    searchById,
    getFlaggedJobById,
    postJob,
    recent,
    report,
    apply,
    clearJobFlags,
    getFlaggedJobs,
    staffSearch,
    staffMoreFilteredSearch,
    staffChangeState,
    employerChangeState,
}