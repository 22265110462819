import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";

import Logo from "../../Assets/logo.png";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import { ThreeDotLoading } from "../react-loading-indicators/Indicator";

const NavBar = () => {
	const location = useLocation();
	const navigate = useNavigate();

    const { authUser, logout } = useAuth();
    const user = authUser();
	let expand = "md";

	const [isLoggingOut, setIsLoggingOut] = useState(false);

	const handleLogout = async () => {
		// call logout endpoint
		try {
			setIsLoggingOut(true);
			await logout();
			setIsLoggingOut(false);
		} catch (error) {
			// display error message
			toast.error(handleErrMsg(error).msg);
			setIsLoggingOut(false);
		}
	}

	return (
		<>
			<div>
				<Navbar
					collapseOnSelect
					expand={expand}
					className="shadow-sm"
					style={{ background: "#f0f0e8" }}
				>
					<Container fluid>
						<Navbar.Brand
							className="d-flex align-items-center fw-bold"
							onClick={() => navigate("/")}
						>
							<img
								src={Logo}
								alt="Logo"
								width="40"
								height="45"
								className="d-inline-block align-text-top pb-2 px-2"
							/>
							The Advancement Place
						</Navbar.Brand>
						<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
						<Navbar.Offcanvas
							id={`offcanvasNavbar-expand-${expand}`}
							aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
							placement="end"
						>
							<Offcanvas.Header closeButton></Offcanvas.Header>
							<Offcanvas.Body>
								<Nav className="justify-content-start mx-auto ">
									<Nav.Link
										eventKey={1}
										onClick={() => navigate("/")}
										className={`navbar-nav nav-item p-2 ${
											location.pathname === "/" &&
											"activeLink link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fw-bold"
										}`}
									>
										Home
									</Nav.Link>

									{user && ( <Nav.Link
										eventKey={2}
										onClick={() => navigate("/jobs")}
										className={`navbar-nav nav-item p-2 ${
											location.pathname === "/jobs" &&
											"activeLink link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fw-bold"
										}`}
									>
										Jobs
									</Nav.Link> )}

									<Nav.Link
										eventKey={3}
										onClick={() => navigate("/about")}
										className={`navbar-nav nav-item p-2 ${
											location.pathname === "/about" &&
											"activeLink link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fw-bold"
										}`}
									>
										About
									</Nav.Link>

									{user && ( <Nav.Link
										eventKey={4}
										onClick={() => navigate("/dashboard")}
										className={`navbar-nav nav-item p-2 ${
											location.pathname === "/dashboard" &&
											"activeLink link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fw-bold"
										}`}
									>
										Dashboard
									</Nav.Link> )}

									<Nav.Link
										eventKey={5}
										onClick={() => navigate("/services")}
										className={`navbar-nav nav-item p-2 ${
											location.pathname === "/services" &&
											"activeLink link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fw-bold"
										}`}
									>
										Services
									</Nav.Link>
								</Nav>

								{!user && ( <Nav.Link
									eventKey={6}
									onClick={() => navigate("/login")}
									className={`navbar-nav nav-item p-2 ${
										location.pathname === "/login" &&
										"activeLink link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fw-bold"
									}`}
								>
									Login
								</Nav.Link> )}

								{!user && ( <Nav.Link
									className={`navbar-nav nav-item p-2 ${
										location.pathname === "/signup" &&
										"activeLink link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fw-bold"
									}`}
									eventKey={7}
									onClick={() => navigate("signup")}
								>
									Sign Up
								</Nav.Link> )}

								{user && ( <Nav.Link
									className={`navbar-nav nav-item p-2 link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover fw-bold ${isLoggingOut && 'disabled'}`}
									eventKey={8}
									onClick={() => handleLogout()}
								>
									{isLoggingOut && <ThreeDotLoading variant="windmill" color="#f78419" size="small" />}
									{ !isLoggingOut && `Logout` }
								</Nav.Link> )}
								
							</Offcanvas.Body>
						</Navbar.Offcanvas>
					</Container>
				</Navbar>
			</div>
		</>
	);
};

export default NavBar;