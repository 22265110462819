import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Badge, Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import { formatDistanceToNow } from "date-fns";

import { Hover, MyContainer } from "../../Components/Styles/GlobalStyle.css";
import WordSpan from "../../Components/WordSpan";
import { statusOptions } from "../../the-advancement-place";
import schema from "../../Utils/yup-schema-validators/staff-job-page-schema";
import ErrorMessage from "../../Components/ErrorMessage";
import genericController from "../../controller/generic-controller";
import jobsController from "../../controller/jobs-controller";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import PaginationLite from "../../Components/PaginationLite";
import staffController from "../../controller/staff-controller";


const StaffJobPage = () => {
    const navigate = useNavigate();

    /*  using this to control useEffect running twice in development, not really working though...
        https://stackoverflow.com/questions/72238175/why-useeffect-running-twice-and-how-to-handle-it-well-in-react
        https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
    */
    const effectRan = useRef(false);

    const currentDate = new Date();

    const [industryOptions, setIndustryOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
  
    const [networkRequest, setNetworkRequest] = useState(true);
    const [locationLoading, setLocationLoading] = useState(true);
    const [industryLoading, setIndustryLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [totalJobCount, setTotalJobCount] = useState(0);
    // mode represents request type: 0 means requeting staff non-viewed jobs, 1 means simple search, 2 means more filtered search
    const [requestMode, setRequestMode] = useState(0);

    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);

    // for pagination
    const [pageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    //  data returned from DataPagination
    const [pagedData, setPagedData] = useState([]);

    const { handleRefresh, logout } = useAuth();

    const customStyles = {
        control: (provided, state) => ({
              ...provided,
              backgroundColor: "#f0f0e8",
              borderColor: "#cecec8ca",
              borderRadius: "2px",
              padding: "10px 0px",
              boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(0, 123, 255, 0.25)" : "none",
              "&:hover": {
                  borderColor: "transparent",
              },
              "&:focus": {
                  boxShadow: "0 0 0 0.25rem rgba(0, 123, 255, 0.25)",
              },
              // height: "48px", // Match Bootstrap's default form control height
              minHeight: "48px", // Ensures the minimum height is 38px
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: "rgba(0, 123, 255, 0.75)", // customize color of the dropdown arrow
        }),
    };

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            more_filter: false,
            startDate: currentDate,
            endDate: currentDate,
            status: statusOptions[0],
        },
    });
    
    const moreFilterValue = watch("more_filter");
    const startDate = watch("startDate");

    // for performing simple search
    const [reqBody, setReqBody] = useState({
        what: "",
        where: [],
        industries: [],
        limit: pageSize,
        idOffset: 0,
    });

    // for performing more filtered search
    const [moreFilteredReqBody, setMoreFilteredReqBody] = useState({
        what: "",
        where: [],
        industries: [],
        status: -1,
        limit: pageSize,
        idOffset: 0,
    });

    useEffect( () => {
        if (!effectRan.current) {
            effectRan.current = true;
            initialize();
        }
    }, []);

	const initialize = async () => {
		try {
            const urls = [ '/locations/active', '/industries/active'];
            const response = await genericController.performGetRequests(urls);
            const { 0: locations, 1: industries } = response;

            //check if the request to fetch locations doesn't fail before setting values to display
            if(locations && locations.data){
                setLocationLoading(false);
                setLocationOptions(locations.data.map( location => ({label: location.name, value: location.name})));
            }

            //  check if the request to fetch indstries doesn't fail before setting values to display
            if(industries && industries.data){
                setIndustryLoading(false);
                setIndustryOptions(industries.data.map( industry => ({label: industry.name, value: industry.name})));
            }

            const nonViewed = await staffController.findNonViewed(reqBody, 1, 0); // 1 represents pageSpan, 0 represents idOffset
            //  check if the request to fetch non yet viewed jobs doesn't fail before setting values to display
            if(nonViewed && nonViewed.data){
                setJobs(nonViewed.data.jobs);
                setTotalJobCount(nonViewed.data.count);
                setNetworkRequest(false);
            }
		} catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return initialize();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
		}
	};

    const onSubmit = async (data) => {
        setRequestMode(2);
        // const temp = { ...moreFilteredReqBody };
        data.status = data.status.value;
        data.limit = pageSize;

        if(data.industries){
            data.industries = data.industries.map(s => s.value);
        }else{
            data.industries = [];
        }

        if(data.where){
            data.where = data.where.map(s => s.value);
        }else{
            data.where = [];
        }

        setMoreFilteredReqBody(data);

        await performMoreFilteredSearch(data);
    };

    const setPageChanged = async (pageNumber) => {
        const startIndex = (pageNumber - 1) * pageSize;
        if(pageNumber === 1) {
            setPagedData(jobs.slice(startIndex, startIndex + pageSize));
        }else if(pageNumber > 1) {
            if(jobs.length > startIndex) {
                const arr = jobs.slice(startIndex, startIndex + pageSize);
                setPagedData(arr);
            }else {
                let idOffset = 0;
                switch (requestMode) {
                    case 0:
                        // get id of last element in jobs array to use as query offset
                        idOffset = jobs[jobs.length - 1].id;
                        paginateFetchNonViewed(pageNumber, pageNumber - currentPage, idOffset);
                        break;
                    case 1:
                        // get id of last element in jobs array to use as query offset
                        idOffset = jobs[jobs.length - 1].id;
                        paginateSimpleSearch(idOffset, pageNumber, pageNumber - currentPage);
                        break;
                    case 2:
                        // get id of last element in jobs array to use as query offset
                        idOffset = jobs[jobs.length - 1].id;
                        paginateMoreFilteredSearch(idOffset, pageNumber, pageNumber - currentPage);
                        break;
                }
            }
        }
    };
    
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter' && !moreFilterValue) {
            const temp = {...reqBody};
            temp.what = event.target.value;
            setReqBody(temp);
            
            await performSimpleSearch(temp);
        }
    };

    const handleLocationChange = async (selected) => {
      // https://blog.openreplay.com/async-data-fetching-with-react-select/
      setSelectedLocation(selected);
      if(!moreFilterValue){
          const temp = {...reqBody};
          temp.where = selected.map(s => s.value);
          setReqBody(temp);
          
          await performSimpleSearch(temp);
        }
    };
  
    const handleIndustryChange = async (selected) => {
        // https://blog.openreplay.com/async-data-fetching-with-react-select/
        setSelectedIndustry(selected);
        if(!moreFilterValue){
            const temp = {...reqBody};
            temp.industries = selected.length > 0 ? selected.map(s => s.value) : [];
            setReqBody(temp);
            
            await performSimpleSearch(temp);
        }
    };

	const performMoreFilteredSearch = async (moreFilteredReqBody) => {
		try {
            setNetworkRequest(true);
            resetStates();
			const jobSearchRes = await jobsController.staffMoreFilteredSearch(moreFilteredReqBody, 1, 0);  // 1 represents the pageSpan, 0 represents idOffset
			if(jobSearchRes.data.jobs.length < 1){
                toast.info("No Match");
            }
            setJobs(jobSearchRes.data.jobs);
            setTotalJobCount(jobSearchRes.data.count);
            setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return performMoreFilteredSearch(moreFilteredReqBody);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}
    
    const performSimpleSearch = async (reqBody) => {
        try {
            setRequestMode(1);
            setNetworkRequest(true);
            resetStates();
            const jobSearchRes = await jobsController.staffSearch(reqBody, 1, 0);  // 1 represents the pageSpan, 0 represents idOffset
            if(jobSearchRes.data.jobs.length < 1){
                toast.info("No Match");
            }
            setJobs(jobSearchRes.data.jobs);
            setTotalJobCount(jobSearchRes.data.count);
            setNetworkRequest(false);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return performSimpleSearch(reqBody);
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    }

    const paginateSimpleSearch = async (idOffset, pageNumber, pageSpan) => {
        try {
            setNetworkRequest(true);
            const response = await jobsController.staffSearch(reqBody, pageSpan, idOffset);;

            //  check if the request to fetch indstries doesn't fail before setting values to display
            if(response && response.data){
                setJobs([...jobs, ...response.data.jobs]);
                /*  normally, we would call setPagedData(nonViewed.data.jobs) here but that isn't necessary because calling setCurrentPage(pageNumber)
                    would cause PaginationLite to re-render as currentPage is part of it's useEffect dependencies. This re-render triggers setPageChanged to be
                    called with currentPage number. the 'else if' block then executes causing setPagedData to be set  */
                setNetworkRequest(false);
            }
            // update page number
            setCurrentPage(pageNumber);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return paginateSimpleSearch(idOffset, pageNumber, pageSpan);
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    }

    const paginateMoreFilteredSearch = async (idOffset, pageNumber, pageSpan) => {
        try {
            setNetworkRequest(true);
            const response = await jobsController.staffMoreFilteredSearch(moreFilteredReqBody, pageSpan, idOffset);;

            //  check if the request to fetch indstries doesn't fail before setting values to display
            if(response && response.data){
                setJobs([...jobs, ...response.data.jobs]);
                /*  normally, we would call setPagedData(nonViewed.data.jobs) here but that isn't necessary because calling setCurrentPage(pageNumber)
                    would cause PaginationLite to re-render as currentPage is part of it's useEffect dependencies. This re-render triggers setPageChanged to be
                    called with currentPage number. the 'else if' block then executes causing setPagedData to be set  */
                setNetworkRequest(false);
            }
            // update page number
            setCurrentPage(pageNumber);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return paginateMoreFilteredSearch(idOffset, pageNumber, pageSpan);
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    }

    const paginateFetchNonViewed = async (pageNumber, pageSpan, idOffset) => {
        try {
            setNetworkRequest(true);
            const response = await staffController.findNonViewed(reqBody, pageSpan, idOffset);

            //  check if the request to fetch indstries doesn't fail before setting values to display
            if(response && response.data){
                setJobs([...jobs, ...response.data.jobs]);
                /*  normally, we would call setPagedData(response.data.jobs) here but that isn't necessary because calling setCurrentPage(pageNumber)
                    would cause PaginationLite to re-render as currentPage is part of it's useEffect dependencies. This re-render triggers setPageChanged to be
                    called with currentPage number. the 'else if' block then executes causing setPagedData to be set  */
                setNetworkRequest(false);
            }
            // update page number
            setCurrentPage(pageNumber);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return paginateFetchNonViewed(pageNumber, pageSpan);
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    }

    const resetStates = () => { 
        setJobs([]);
        setCurrentPage(1);
        setTotalJobCount(0);
    }

    const buildCardItem = (job, i) => {
        return <Hover key={i}>
              <Link to={`/dashboard/staff/jobs/${job.id}/${job.title.replace(/[^a-zA-Z0-9 ]/g, '').split(' ').join('+')}`} target="_blank" rel="noopener noreferrer" 
                  className="btn text-start border border-secondary-subtle p-3 rounded-3 onHover mb-4 w-100" style={{boxShadow: 'black 1px 1px 2px'}}
                //   onClick={() => navigate("job-description")}
              >
                  <div className="mb-2">
                      <Badge
                        className={`ms-auto text-dark fw-bold bg-primary-subtle fw-bolder align-self-start p-2 me-3`}
                      >
                        <span>{formatDistanceToNow(job.createdAt, {addSuffix: true})}</span>
                      </Badge>
                      <Badge
                        className={`ms-auto text-dark fw-bold bg-success-subtle fw-bolder align-self-start p-2`}
                      >
                        <span> {job.available_openings} position{job.available_openings > 1 && "s"} </span>
                      </Badge>
                  </div>
                  <div className="mb-2">
                      <h3 className="py-2">
                          <WordSpan> {job.title} </WordSpan>
                      </h3>
                      <div className="d-flex gap-sm-3 flex-column flex-sm-row">
                          <small className="fw-bold"> {job.JobLocation.name} </small>
                          <small className="fw-bold"> {job.work_type === true ? "full time" : "part time"} </small>
                          <small className={(job.min_salary === 0 && job.max_salary === 0) ? 'text-danger fw-bold' : '' + " fw-bold"}>
                              {(job.min_salary === 0 && job.max_salary === 0) ? "Confidential" : `${job.min_salary} up to ${job.max_salary}`}
                          </small>
                      </div>
                  </div>
                  <div className="mb-2">
                      <p>
                          {job.summary}
                      </p>
                  </div>
                  <div className="">
                      <Badge className={`ms-auto bg-danger-subtle text-secondary fw-bolder p-2 me-2`} >
                          {job.JobIndustry.name}
                      </Badge>
                      <small className="fw-bold text-success"> {job.applicant.length} Application{job.applicant.length > 1 && "s"} </small>
                  </div>
              </Link>
        </Hover>
    };

    const buildJobCards = pagedData.map((job, i) => { return buildCardItem(job, i) });

    return (
        <>
            <MyContainer $padding_y="40px" className="container">
                <h3 className="bungee-regular mb-4 ms-3">
                    Search 
                    <WordSpan>
                        <span className="bungee-regular"> Filter </span>
                    </WordSpan>
                </h3>
                <div className="border py-4 px-5 bg-white-subtle rounded-4" style={{boxShadow: 'black 3px 2px 5px'}}>
                    <Row className="align-items-center">
                        <Col sm className="d-flex flex-column pt-3 pb-3">
                            <label className="fw-bold mb-2 fs-6 mt-auto" htmlFor="floatingInput1">
                                What
                            </label>
                            <FloatingLabel controlId="floatingInput1" label="Job title" className="mb-1">
                                <Form.Control className="border border-0 outline-none" type="text" placeholder="job title..."
                                    {...register("what")} onKeyDown={handleKeyDown}
                                />
                                <ErrorMessage source={errors.what} />
                            </FloatingLabel>
                        </Col>
                        <Col sm className="d-flex flex-column pt-3 pb-3">
                            <label className="fw-bold mb-2 fs-6 mt-auto" htmlFor="floatingInput2">
                                Where
                            </label>
                            <Controller
                                name="where"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        isMulti
                                        styles={customStyles}
                                        placeholder={"Job location"}
                                        name="whereOption"
                                        {...register("where")}
                                        onChange={(val) => {
                                            field.onChange(val);
                                            handleLocationChange(val);
                                        }}
                                        value={selectedLocation}
                                        options={locationOptions}
								        isLoading={locationLoading}
                                        className="border-0 bg-secondary"
                                        classNamePrefix="select"
                                    />
                              )}
                            />
                            <ErrorMessage source={errors.where} />
                        </Col>
                        <Col sm className="d-flex flex-column pt-3 pb-3">
                            <label className="fw-bold mb-2 fs-6 mt-auto" htmlFor="floatingInput3" >
                                Industry
                            </label>
                            <Controller
                                name="industries"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        isMulti
                                        styles={customStyles}
                                        placeholder={"Job sector..."}
                                        name="industryOption"
                                        onChange={(val) => {
                                            field.onChange(val);
                                            handleIndustryChange(val);
                                        }}
                                        value={selectedIndustry}
                                        options={industryOptions}
								        isLoading={industryLoading}
                                        className="border-0 bg-secondary"
                                        classNamePrefix="select"
                                    />
                                )}
                            />
                            <ErrorMessage source={errors.industries} />
                        </Col>
                    </Row>
                    <div className="d-flex gap-3">
                        <Form.Group controlId="moreFilterValue">
                            <Controller
                                name="more_filter"
                                control={control}
                                render={({ field }) => (
                                    <Form.Check
                                        type="checkbox"
                                        label="More filter"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                    <ErrorMessage source={errors.more_filter} />
                </div>

                { moreFilterValue && (
                    <div className="border py-2 px-5 bg-white-subtle rounded-4 mt-3" style={{boxShadow: 'black 3px 2px 5px'}}>
                        <Row>
                            <Col sm lg="3" className="mt-3 mt-md-0">
                              <Form.Group className="w-100" as={Col} sm="6" controlId="status" >
                                  <Form.Label className="fw-bold">Status</Form.Label>
                                  <Controller
                                      name="status"
                                      control={control}
                                      render={({ field: { onChange, value } }) => (
                                          <Select
                                              required
                                              defaultValue={statusOptions[0]}
                                              {...register("status")}
                                              value={statusOptions.find((e) => e.value === value)}
                                              options={statusOptions}
                                              onChange={(val) => onChange(val)}
                                          />
                                      )}
                                  />
                                  <ErrorMessage source={errors.status} />
                              </Form.Group>
                            </Col>
                            <Col sm lg="3" className="mt-3 mt-md-0">
                                <Form.Label className="fw-bold">Start Date</Form.Label>
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({ field }) => (
                                      <Datetime
                                        {...field}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        dateFormat="DD/MM/YYYY"
                                        inputProps={{
                                            placeholder: "Choose start date",
                                            className: "form-control",
                                            readOnly: true, // Optional: makes input read-only
                                        }}
                                        onChange={(date) => {
                                            setValue('endDate', date.toDate());
                                            field.onChange(date ? date.toDate() : null);
                                        }
                                        }
                                      />
                                    )}
                                />
                                <ErrorMessage source={errors.startDate} />
                            </Col>
                            <Col sm lg="3" className="mt-3 mt-md-0">
                                <Form.Label className="fw-bold">End Date</Form.Label>
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({ field }) => (
                                      <Datetime
                                        {...field}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        dateFormat="DD/MM/YYYY"
                                        inputProps={{
                                            placeholder: "Choose end date",
                                            className: "form-control",
                                            readOnly: true, // Optional: makes input read-only
                                        }}
                                        onChange={(date) =>
                                          field.onChange(date ? date.toDate() : null)
                                        }
                                        isValidDate={(current) => {
                                            // Ensure end date is after start date
                                            return (
                                                !startDate || current.isSameOrAfter(startDate, "day")
                                            );
                                        }}
                                      />
                                    )}
                                />
                                <ErrorMessage source={errors.endDate} />
                            </Col>
                            <Col sm lg="3" className="align-self-end text-center mt-3">
                                <Button className="w-100" onClick={handleSubmit(onSubmit)}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}

                <div className="mt-5">
                    <h3 className="bungee-regular mb-4 ms-3">
                        Posted Jobs
                    </h3>
                    <div className="p-2 text-center">
                          { networkRequest && <ThreeDotLoading variant="pulsate" color="#f78419" size="large" /> }
                          { buildJobCards }
                    </div>
                </div>

                <div className="mt-5">
                    <PaginationLite itemCount={totalJobCount} pageSize={pageSize} setPageChanged={setPageChanged} pageNumber={currentPage} />
                </div>
            </MyContainer>
        </>
    );
};

export default StaffJobPage;
