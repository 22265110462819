import * as yup from "yup";
import { yearsOptions } from "../../the-advancement-place";

const getArray = (option) => {
	return option.map((e) => e.value);
};

const industrySchema = yup.object().shape({
    industry: yup.string().required("Industry name is required")
});

const locationSchema = yup.object().shape({
    location: yup.string().required("Location name is required")
});

const qualificationSchema = yup.object().shape({
    qualification: yup.string().required("Qualification name is required")
});

const experienceSchema = yup.object().shape({
    experience: yup.string().required("Experirence name is required")
});

const yearSchema = yup.object().shape({
    year: yup.number().oneOf(getArray(yearsOptions), "Select from the list!").required("Select current year")
});

export default {
    industrySchema,
    locationSchema,
    qualificationSchema,
    experienceSchema,
    yearSchema
}