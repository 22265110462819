import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { format } from "date-fns";

import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import WordSpan from "../../Components/WordSpan";
import genericController from "../../controller/generic-controller";
import staffController from "../../controller/staff-controller";
import { useAuth } from "../../app-context/auth-user-context";
import { ThreeDotLoading } from '../../Components/react-loading-indicators/Indicator';
import handleErrMsg from "../../Utils/error-handler";
import ConfirmDialogComp from "../../Components/ConfirmDialogComp";

const UnverifiedMails = () => {
    const navigate = useNavigate();

	const [networkRequest, setNetworkRequest] = useState(true);
	const [unverifiedMails, setUnverifiedMails] = useState([]);
    const [totalUnverifiedMails, setTotalUnverifiedMails] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");
    const [confirmationReason, setConfirmationReason] = useState("");
    const [selectedMail, setSelectedMail] = useState(null);

    const { handleRefresh, logout } = useAuth();

    useEffect( () => {
		initialize();
    }, []);

	const initialize = async () => {
		try {
            const urls = [ '/staff/unverified-mails/view'];
            const response = await genericController.performGetRequests(urls);
            const { 0: unverified } = response;

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(unverified && unverified.data){
                setUnverifiedMails(unverified.data);
                setTotalUnverifiedMails(unverified.data.length);
            }
            setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
        setNetworkRequest(false);
	};

    const handleOpenModal = (reason, mail) => {
		let msg = '';
		switch (reason) {
			case 'single':
				msg = `Remove ${mail} ? Caution, action cannot be undone`;
				break;
			case 'clear':
				if (totalUnverifiedMails > 0) {
					msg = 'Clear all Unverified mails? Caution, action cannot be undone';
				}else {
					return;
				}
				break;
		}
		setConfirmationReason(reason);
        setSelectedMail(mail);
        setDisplayMsg(msg);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

	const handleConfirmAction = async () => {
        setShowModal(false);
		switch (confirmationReason) {
			case 'single':
				removeMail();
				break;
			case 'clear':
				clearAll();
				break;
		}
	}

	const removeMail = async () => {
		try {
			setNetworkRequest(true);
			await staffController.removeUnverifiedMail(selectedMail);
            const arr = unverifiedMails.filter(mail => mail.email !== selectedMail);
            setTotalUnverifiedMails(arr.length);
            setUnverifiedMails(arr);
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return removeMail();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	}

	const clearAll = async () => {
		try {
			await staffController.clearUnverifiedMails();
			setUnverifiedMails([]);
            setTotalUnverifiedMails(0);
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return clearAll();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const buildUnverifiedMailRow = (unverifiedMail, i) => {
		return <tr key={i}>
			<td>{i + 1}</td>
			<td> {unverifiedMail.email} </td>
			<td> {format(unverifiedMail.createdAt, "MM/dd/yyyy")} </td>
			<td className="btn-link" onClick={() => handleOpenModal('single', unverifiedMail.email)}> remove </td>
		</tr>
	};

    const buildUnverifiedMailRows = unverifiedMails.map((unverifiedMail, i) => { return buildUnverifiedMailRow(unverifiedMail, i) });

	return (
		<>
			<MyContainer $padding_y="40px" className="container">
				<h3 className="bungee-regular mb-4 ms-3">Unverified <WordSpan className='bungee-regular'>Mails</WordSpan></h3>
				<h6 className="ms-3">Total: <WordSpan className='bungee-regular'> {totalUnverifiedMails} </WordSpan></h6>

				<div className="text-center p-2">
					{ networkRequest && <ThreeDotLoading variant="pulsate" color="#f78419" size="large" /> }
                    <Table
						className="bg-light border border-secondary-subtle"
						striped
						variant="light"
						responsive="sm"
					>
						<thead>
							<tr>
								<th>#</th>
								<th>e-mail</th>
								<th>Registered Date</th>
								<th className="btn-link" onClick={() => handleOpenModal('clear')}>Remove All</th>
							</tr>
						</thead>
						<tbody>
							{buildUnverifiedMailRows}
						</tbody>
					</Table>
				</div>
                <ConfirmDialogComp show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmAction} message={displayMsg} />
			</MyContainer>
		</>
	);
};

export default UnverifiedMails;