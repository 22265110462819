import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import logo from "../Assets/logo.png";
import Image1 from "../Assets/svg/undraw_login_re_4vu2.svg";
import { MyContainer } from "../Components/Styles/GlobalStyle.css";
import { useAuth } from "../app-context/auth-user-context";
import handleErrMsg from '../Utils/error-handler';
import ErrorMessage from "../Components/ErrorMessage";
import { ThreeDotLoading } from "../Components/react-loading-indicators/Indicator";

const Login = () => {
	const navigate = useNavigate();

	const { clientLogin, authUser } = useAuth();
	const user = authUser();

	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const schema = yup.object().shape({
		email: yup
			.string()
			.email("A valid email format is required")
			.required("Email is required"),
		pw: yup
			.string()
			.min(6, "Password must be a min of 6 characters!")
			.required("Input correct password"),
	});

	// Yup Integration with "react-hook-form"
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

    useEffect( () => {
		if(user) {
			navigate('/');
		}
    }, []);

	const onSubmit = async (data) => {
		try {
			setIsLoggingIn(true);
			await clientLogin(data);
			setIsLoggingIn(false);
			navigate('/');
		} catch (ex) {
			setIsLoggingIn(false);
			toast.error(handleErrMsg(ex).msg);
		}
	};

	return (
		<>
			<MyContainer
				className="d-flex flex-column align-items-center justify-content-center"
				height="80vh"
				$padding_y="40px"
			>
				<div className="row container mx-auto">
					<div className="col-12 col-sm-6 col-xl-9 d-flex align-items-center d-none d-sm-flex">
						<img src={Image1} alt="" width={"100%"} height={"400px"} />
					</div>
					<main className="col-12 col-sm-6 col-xl-3 form-signin m-auto">
						<form className="text-center">
							<img className="mb-4" src={logo} alt="" width="90" height="120" />
							<h1 className="h3 mb-3 fw-normal">Please sign in</h1>

							<div className="form-floating mb-3">
								<input
									type="email"
									className="form-control rounded-bottom-0 mb-1"
									id="floatingInput"
									placeholder="name@example.com"
                					{...register("email")}
								/>
								<label htmlFor="floatingInput">Email address</label>
								<ErrorMessage source={errors.email} />
							</div>
							<div className="form-floating">
								<input
									type="password"
									className="form-control rounded-top-0 mb-4"
									id="floatingPassword"
									placeholder="Password"
                					{...register("pw")}
								/>
								<label htmlFor="floatingPassword">Password</label>
								<ErrorMessage source={errors.pw} />
							</div>

							<button
								className="btn btn-outline-secondary w-100 my-2 py-2"
								type="submit"
              					onClick={handleSubmit(onSubmit)}
							>
								{ isLoggingIn && <ThreeDotLoading color="#f78419" size="medium" text='please wait' textColor="#f78419" /> }
								{!isLoggingIn && `Sign In` }
							</button>
							<Link
								to={`/client/pwreset`}
								// target="_blank"
								rel="noopener noreferrer"
							>
								Forgot password?
							</Link>
							<p className="mt-5 mb-3 text-body-secondary">&copy; 2024</p>
						</form>
					</main>
				</div>
			</MyContainer>
		</>
	);
};

export default Login;
