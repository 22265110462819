import React, { useEffect, useRef, useState } from "react";
import { Button, Row, Col, Badge, Table } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { FaLocationDot, FaPeopleGroup } from "react-icons/fa6";
import { toast } from "react-toastify";

import { MyContainer } from "../../Components/Styles/GlobalStyle.css";
import WordSpan from "../../Components/WordSpan";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";

import Editor from "../../Components/quill/quill-editor";
import Quill from 'quill';
import jobsController from "../../controller/jobs-controller";
import ConfirmDialogComp from "../../Components/ConfirmDialogComp";

const Delta = Quill.import('delta');

const MyJobView = () => {
	const navigate = useNavigate();
	const {pathname} = useLocation();

    const { handleRefresh, logout } = useAuth();
	
	const [networkRequest, setNetworkRequest] = useState(true);
	const [jobId, setJobId] = useState();
	const [job, setJob] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");

	// Use a ref to access the quill instance directly
	const quillRef = useRef();

    useEffect( () => {
		const pathArr = pathname.split("/");
		setJobId(pathArr[pathArr.length - 2]);
		if(jobId) {
			initialize();
		}
    }, [jobId]);

	const initialize = async () => {
		try {
            const response = await jobsController.searchById(jobId);

            //check if the request to fetch active staff doesn't fail before setting values to display
            if(response && response.data){
				setNetworkRequest(false);
				setJob(response.data);

				const quillData = JSON.parse(response.data.desc);
                // const quillData = response.data.desc;
				let content = new Delta();
				quillData.forEach(element => {
					content.insert(element.insert, element.attributes)
				});
				quillRef.current.setContents(content);
            }

		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				// Incase of 401 Unauthorized, navigate to 404
				if(error.response?.status === 401){
					navigate('/404');
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	};

    const handleOpenModal = () => {
		if(job.state === 1){
			return toast.info(`Can't suspend a closed job`);
		}
		let msg = `Close ${job.title} ?`;
        setDisplayMsg(msg);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

	const handleConfirmAction = async () => {
        setShowModal(false);
		try {
			setNetworkRequest(true);
			const reqBody = {
				id: job.id,
				state: 1
			};
			await jobsController.employerChangeState(reqBody);
			const updated = {...job};
			updated.state = reqBody.state;
			setJob(updated);
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return handleConfirmAction();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	};

	return (
		<>
			<MyContainer $padding_y="20px">
				<div className="container">
					<div className="mb-4" id="head">
						<h2>
							<WordSpan> {job?.title} </WordSpan>
						</h2>
						<Row className="mb-2">
							<Col>
                                <div className="d-flex gap-3 align-items-center text-muted">
                                    { job.createdAt && <small className="">{formatDistanceToNow(job.createdAt, {addSuffix: true})}</small>}
                                    <span>
                                        <FaLocationDot /> {job?.JobLocation?.name}
                                    </span>
                                    <br />
                                    <span> {job?.work_type === 1 ? 'Full time' : 'Part Time'} </span>
                                </div>
							</Col>
						</Row>
						
						<Row>
							<Col xs="12" sm="9">
								{/* <Badge className="bg-danger-subtle text-muted p-2">Suspended</Badge> */}
								<h2 className={`${job.state > 0 ? 'text-danger' : 'text-primary'}`}>
									{job.state === 0 ? 'Active' : job.state === 1 ? 'Closed' : 'Suspended'}
								</h2>
							</Col>
							<Col xs="12" sm="3" className="text-sm-end my-2 my-sm-0">
								{job.state === 0 && <Button variant={'danger'} onClick={handleOpenModal} className="w-75" disabled={networkRequest}>
									{"  "}
									Close
								</Button>}
							</Col>
						</Row>
					</div>
					<hr />
					<div id="body">
						<h4 className="text-muted py-1 mb-2 fw-bold">Job Summary</h4>
						<p className="fw-normal mb-3">
							{job?.summary}.
						</p>
						<hr />
						<h4 className="text-muted py-1 mb-2 fw-bold">Job Description</h4>
						<Editor ref={quillRef} readOnly={true} withToolBar={false} />
						<hr className="my-4" />
					</div>
				</div>
                <ConfirmDialogComp show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmAction} message={displayMsg} />
			</MyContainer>
		</>
	);
};

export default MyJobView;
