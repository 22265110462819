import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "./App.css";
import NavBar from "./Components/Navbar/Navbar";
import Home from "./Routes/Home";
import AboutUs from "./Routes/AboutUs";
import Apply from "./Routes/Apply";
import Contact from "./Routes/Contact";
import Footer from "./Components/Footer";
import Services from "./Routes/Services";
import Login from "./Routes/Login";
import SignUp from "./Routes/SignUp";
import EmployerRegComp from "./Components/RegistrationComp/Employer/EmployerRegComp";
import SeekerRegComp from "./Components/RegistrationComp/Seeker/SeekerRegComp";
import Main from "./Routes/Dashboard/Main-Dashboard";
import { MyContainer } from "./Components/Styles/GlobalStyle.css";
import SeekerProfileEditPage from "./Routes/Dashboard/SeekerProfileEditPage";
import Employers from "./Routes/Dashboard/Employers";
import AddUserPage from "./Routes/Dashboard/AddUserPage";
import JobSeekers from "./Routes/Dashboard/JobSeekers";
import PostJobPage from "./Routes/Dashboard/PostJobPage";
import ViewAllStaffPage from "./Routes/Dashboard/ViewAllStaffPage";
import Jobs from "./Routes/Jobs";
import ProtectedRoute from "./Routes/ProtectedRoute";
import { AuthProvider } from "./app-context/auth-user-context";
import JobDescription from "./Routes/JobDescription";
import NotFoundPage from "./Routes/NotFoundPage";
import StaffLogin from "./Routes/StaffLogin";
import NotificationsPage from "./Routes/NotificationsPage";
import EmployerProfileEditPage from "./Routes/Dashboard/EmployerProfileEditPage";
import MyJobs from "./Routes/Dashboard/MyJobs";
import ChangePassword from "./Components/ChangePassword";
import JobReport from "./Routes/JobReport";
import StaffProfileEditPage from "./Routes/Dashboard/StaffProfileEditPage";
import ViewStaffPage from "./Routes/Dashboard/ViewStaffPage";
import StaffJobPage from "./Routes/Dashboard/StaffJobPage";
import SettingsPage from "./Routes/Dashboard/SettingsPage";
import ReviewTermsAndAgreement from "./Routes/Dashboard/terms-and-agreement-review";
import StaffJobView from "./Routes/Dashboard/StaffJobViewPage";
import SeekerProfilePage from "./Routes/Dashboard/SeekerProfilePage";
import EmployerProfilePage from "./Routes/Dashboard/EmployerProfilePage";
import MyJobView from "./Routes/Dashboard/MyJobView";
import FlaggedJobs from "./Routes/Dashboard/FlaggedJobs";
import FlaggedJobDescription from "./Routes/Dashboard/FlaggedJobDescription";
import TermsAndAgreement from "./Routes/terms-and-agreement";
import UnverifiedMails from "./Routes/Dashboard/unverified-mails";
import StaffPassReset from "./Routes/StaffPassReset";
import ClientPassReset from "./Routes/ClientPassReset";

function App() {
	return (
		<MyContainer
			$padding_y="0px"
			height="100vh"
			className="App animate__animated d-flex flex-column justify-content-between"
		>
			<AuthProvider>
				<NavBar />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="about" element={<AboutUs />} />
					<Route path="apply" element={<Apply />} />
					<Route path="contact" element={<Contact />} />
					<Route path="services" element={<Services />} />
					<Route path="terms-and-agreement/review" element={<ReviewTermsAndAgreement />} />
					<Route path="terms-and-agreement" element={<TermsAndAgreement />} />
					<Route path="/jobs" element={<ProtectedRoute />}>
						<Route path=":id/:title"  element={<JobDescription />} />
						<Route path="report"  element={<JobReport />} />
						<Route path="reported"  element={<FlaggedJobs />} />
						<Route path="reported/:id/:title"  element={<FlaggedJobDescription />} />
						<Route index element={<Jobs />} />
					</Route>

					{/* Auth */}
					<Route path="login" element={<Login />} />
					<Route path="staff/login" element={<StaffLogin />} />
					<Route path="client/pwreset" element={<ClientPassReset />} />
					<Route path="staff/pwreset" element={<StaffPassReset />} />
					<Route path="signup" element={<SignUp />} />
					<Route path="signup/employer" element={<EmployerRegComp />} />
					<Route path="signup/seeker" element={<SeekerRegComp />} />

					{/* Dashboard Quick Menu */}
					<Route path="/dashboard" element={<ProtectedRoute />}>
						<Route path="add-user" element={<AddUserPage />} />
						<Route path="emails/unverified" element={<UnverifiedMails />} />
						<Route path="seeker/edit-profile" element={<SeekerProfileEditPage />} />
						<Route path="employer/edit-profile" element={<EmployerProfileEditPage />} />
						<Route path="staff" >
							<Route path="jobs" element={<StaffJobPage />} />
							<Route path="jobs/:id/:title" element={<StaffJobView />} />
							<Route path="edit-profile" element={<StaffProfileEditPage />} />
						</Route>
						<Route path="client/my-jobs" element={<MyJobs />} />
						<Route path="client/my-jobs/:id/:title" element={<MyJobView />} />
						<Route path="employers" element={<Employers />} />
						<Route path="seekers" element={<JobSeekers />} />
						<Route path="seekers/:id/:initials" element={<SeekerProfilePage />} />
						<Route path="employers/:id/:initials" element={<EmployerProfilePage />} />
						<Route path="post-a-job" element={<PostJobPage />} />
						<Route path="all-staff" >
							<Route path=":staff/:id" element={<ViewStaffPage />} />
							<Route index element={<ViewAllStaffPage />} />
						</Route>
						<Route path="notifications-page" element={<NotificationsPage />} />
						<Route path="change-pw" element={<ChangePassword />} />
						<Route path="staff/settings" element={<SettingsPage />} />
						<Route index element={<Main />} />
					</Route>

					{/* not found */}
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
				<Footer />
			</AuthProvider>
			<ToastContainer />
		</MyContainer>
	);
}

export default App;
