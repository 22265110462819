import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Badge, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { Hover } from "../../Components/Styles/GlobalStyle.css";
import WordSpan from "../../Components/WordSpan";
import { useAuth } from "../../app-context/auth-user-context";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import clientController from "../../controller/client-controller";
import handleErrMsg from "../../Utils/error-handler";
import ErrorMessage from "../../Components/ErrorMessage";
import PaginationLite from "../../Components/PaginationLite";

const JobSeekers = () => {
    const navigate = useNavigate();

    /*  using this to control useEffect running twice in development, not really working though...
        https://stackoverflow.com/questions/72238175/why-useeffect-running-twice-and-how-to-handle-it-well-in-react
        https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
    */
    const effectRan = useRef(false);

    const [networkRequest, setNetworkRequest] = useState(true);
    const [clients, setClients] = useState([]);
    const [totalClientCount, setTotalClientCount] = useState(0);

    // for pagination
    const [pageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    //  data returned from DataPagination
    const [pagedData, setPagedData] = useState([]);

    const { handleRefresh, logout } = useAuth();

    // for performing simple search
    const [reqBody, setReqBody] = useState({
        name: "",
        limit: pageSize,
        idOffset: 0,
		acc_type: "S",
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect( () => {
        if (!effectRan.current) {
            effectRan.current = true;
            initialize();
        }
    }, []);

	const initialize = async () => {
		setNetworkRequest(true);
		try {
			const response = await clientController.findAllByType(reqBody, 1);  // 1 represents the pageSpan

			//check if the request to fetch indstries doesn't fail before setting values to display
			if(response && response.data){
				setClients(response.data.clients);
				setTotalClientCount(response.data.count);
			}
			setNetworkRequest(false);
		} catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return initialize();
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
			setNetworkRequest(false);
		}
	};

    const setPageChanged = async (pageNumber) => {
        const startIndex = (pageNumber - 1) * pageSize;
        if(pageNumber === 1) {
            setPagedData(clients.slice(startIndex, startIndex + pageSize));
        }else if(pageNumber > 1) {
            if(clients.length > startIndex) {
                const arr = clients.slice(startIndex, startIndex + pageSize);
                setPagedData(arr);
            }else {
				// get id of last element in clients array to use as query offset
                const body = {...reqBody};
                body.idOffset = clients[clients.length - 1].id;
                setReqBody(body);
				paginateFetch(body, pageNumber, pageNumber - currentPage);
            }
        }
    };

	const search = async (data) => {
        resetStates();
        const body = {...reqBody};
        body.name = data.name;
        body.idOffset = 0;
        setReqBody(body);
        try {
            setNetworkRequest(true);
			const response = await clientController.findAllByType(body, 1);  // 1 represents the pageSpan
			if(response.data.clients.length < 1){
                toast.info("No Match");
            }
            setClients(response.data.clients);
            setTotalClientCount(response.data.count);
            setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return search(data);
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

    const paginateFetch = async (reqBody, pageNumber, pageSpan) => {
        try {
            setNetworkRequest(true);
            const response = await clientController.findAllByType(reqBody, pageSpan);

            //  check if the request to fetch indstries doesn't fail before setting values to display
            if(response && response.data){
                setClients([...clients, ...response.data.clients]);
                /*  normally, we would call setPagedData(nonViewed.data.clients) here but that isn't necessary because calling setCurrentPage(pageNumber)
                    would cause PaginationLite to re-render as currentPage is part of it's useEffect dependencies. This re-render triggers setPageChanged to be
                    called with currentPage number. the 'else if' block then executes causing setPagedData to be set  */
                setNetworkRequest(false);
            }
            // update page number
            setCurrentPage(pageNumber);
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                    await handleRefresh();
                    return paginateFetch(reqBody, pageNumber, pageSpan);
                }
                // Incase of 401 Unauthorized, navigate to 404
                if(error.response?.status === 401){
                    navigate('/dashboard');
                }
                // display error message
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    }

    const resetStates = () => { 
        setClients([]);
        setCurrentPage(1);
        setTotalClientCount(0);
    }

    const buildClientCard = (client, i) => {
        const initials = Array.from(client.fname)[0] + Array.from(client.lname)[0];
		return <Hover key={i} className="col-md-4 col-sm-12">
			<Link to={`${client.id}/${initials}`} target="_blank" rel="noopener noreferrer"
				className="btn text-start px-3 border border-2 rounded-3 p-2 active w-100"
				// style={{ minWidth: "23rem" }}
			>
				<div className="row">
					<div className="col-2">
						<span className="border rounded-circle bg-light shadow-sm p-1 fw-bold noto-sans-font">
							{Array.from(client.fname)[0] + Array.from(client.lname)[0]}
						</span>
					</div>
					<div className="col-7">
						<p className="overflow-ellipsis fw-bold m-1">{ client.fname }</p>
						<p className="overflow-ellipsis m-1">{ client.lname }</p>
						<p className="overflow-ellipsis m-1">{ client.phone }</p>
						<p className="overflow-ellipsis fw-bold text-success m-1">{ client.email }</p>
						<small className="text-muted fw-bold m-1">
							Created: {format(client.createdAt, "MM/dd/yyyy")}
						</small>
					</div>
					<div className="col-3">
						<Badge
							className={`ms-auto text-secondary fw-bold align-self-start ${
								client.status ? "bg-primary-subtle" : "bg-danger-subtle"
							}`}
						>
							<span>{client.status ? "Active" : "Inactive"}</span>
						</Badge>
					</div>
				</div>
			</Link>
		</Hover>
    };

    const buildClientCards = pagedData.map((client, i) => buildClientCard(client, i) );

	return (
		<>
			<div className="container-md">
				<h1 className="text-center pt-3 noto-sans-font m-2">
					Job <WordSpan>Seekers</WordSpan>
				</h1>
				<div className="row mt-3 d-flex gy-3">
					<div className="col-md-6 col-sm-12">
						<div className="container-fluid">
							<div className="row d-flex gy-3">
								<div className="col-md-8 col-sm-12 d-flex">
									<Form.Control {...register("name")} />
                                	<ErrorMessage source={errors.name} />
								</div>
								<div className="col-md-4 col-sm-12 d-flex justify-content-center">
									<Button className="w-75" onClick={handleSubmit(search)} disabled={networkRequest}>
										Search
                                        { networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 d-flex justify-content-center">
                    	<PaginationLite itemCount={totalClientCount} pageSize={pageSize} setPageChanged={setPageChanged} pageNumber={currentPage} />
					</div>
				</div>
				<div className="container mt-4">
                    <div class="row g-3">
                        { buildClientCards }
                        { networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
                    </div>
				</div>
			</div>
		</>
	);
};

export default JobSeekers;
