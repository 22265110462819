import { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDistanceToNow } from "date-fns";

import { Hover, MyContainer } from "../../Components/Styles/GlobalStyle.css";
import WordSpan from "../../Components/WordSpan";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import DataPagination from "../../Components/DataPagination";
import { useAuth } from "../../app-context/auth-user-context";
import clientController from "../../controller/client-controller";
import handleErrMsg from "../../Utils/error-handler";

const MyJobs = () => {
	const navigate = useNavigate();

	const [jobs, setJobs] = useState([]);
	const [jobsLoading, setJobsLoading] = useState(true);
    // for pagination
    const [currentPage] = useState(1);
    //  data returned from DataPagination
    const [pagedData, setPagedData] = useState([]);

    const { handleRefresh, logout } = useAuth();

    useEffect( () => {
		initialize();
    }, []);

	const initialize = async () => {
		try {
			const jobSearchRes = await clientController.findMyJobs();
            console.log(jobSearchRes);
			setJobs(jobSearchRes.data);
			setJobsLoading(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	};

    const pageChanged = (paginatedData) => {
        setPagedData(paginatedData);
    };

	const buildCardItem = (job, i) => {
		return <div className="p-2" key={i}>
			<Hover>
				<Link to={`${job.id}/${job.title.replace(/[^a-zA-Z0-9 ]/g, '').split(' ').join('+')}`}
					className="btn text-start border border-secondary-subtle p-3 rounded-3 w-100 shadow"
				>
					<div className="mb-2">
						<Badge
							className={`ms-auto text-dark fw-bold bg-primary-subtle fw-bolder align-self-start p-2 me-3`}
						>
							<span>{formatDistanceToNow(job.createdAt, {addSuffix: true})}</span>
						</Badge>
						<Badge
							className={`ms-auto text-dark fw-bold bg-success-subtle fw-bolder align-self-start p-2`}
						>
							<span> {job.available_openings} position{job.available_openings > 1 && "s"} </span>
						</Badge>
					</div>
					<div className="mb-2">
						<h3 className="py-2">
							<WordSpan> {job.title} </WordSpan>
						</h3>
						<div className="d-flex gap-sm-3 flex-column flex-sm-row">
							<small className="fw-bold"> {job.JobLocation.name} </small>
							<small className="fw-bold"> {job.work_type === true ? "full time" : "part time"} </small>
							<small className={(job.min_salary === 0 && job.max_salary === 0) ? 'text-danger' : '' + " fw-bold"}> 
								{(job.min_salary === 0 && job.max_salary === 0) 
									? "Confidential" 
									: `${job.min_salary.toLocaleString('en-US', { style: 'currency', currency: 'NGN' })} up to 
										${job.max_salary.toLocaleString('en-US', { style: 'currency', currency: 'NGN' })}`}
							</small>
						</div>
					</div>
					<div className="mb-2">
						<p>
							{job.summary}
						</p>
					</div>
					<Badge
						className={`ms-auto bg-success-subtle text-secondary fw-bolder align-self-start p-2`}
					>
						{job.JobIndustry.name}
					</Badge>
				</Link>
			</Hover>
		</div>
	};

    const buildJobCards = pagedData.map((job, i) => { return buildCardItem(job, i) });

	return (
		<>
			<MyContainer $padding_y="40px" className="container">
				<div className="mt-5">
					<h3 className="fw-bold ms-3">My Jobs</h3>
					{ buildJobCards }
				</div>

				<div className="mt-5">
					<DataPagination itemCount={jobs ? jobs.length : 0} pageSize={10} pageChanged={pageChanged} data={jobs}
						pageNumber={currentPage} />
				</div>

				<div className="text-center">
					{ jobsLoading && <ThreeDotLoading variant="pulsate" color="#f78419" size="large" /> }
				</div>
			</MyContainer>
		</>
	);
}

export default MyJobs;