import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Select from "react-select";

import ErrorMessage from "../ErrorMessage";
import schema from "../../Utils/yup-schema-validators/post-job-schema";
import { workTypeOptions } from "../../the-advancement-place";
import { useAuth } from "../../app-context/auth-user-context";
import genericController from "../../controller/generic-controller";
import handleErrMsg from "../../Utils/error-handler";

const PostJobDetailsForm = ({ setPage, page, formData, setFormData }) => {

    const { handleRefresh, logout } = useAuth();

	const [industryOptions, setIndustryOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [experienceLevelOptions, setExperienceLevelOptions] = useState([]);
	const [minimumQualificationOptions, setMinimumQualificationOptions] = useState([]);

	const [locationLoading, setLocationLoading] = useState(true);
	const [industryLoading, setIndustryLoading] = useState(true);
	const [experienceLoading, setExperienceLoading] = useState(true);
	const [qualificationLoading, setQualificationLoading] = useState(true);
	
	// Yup Integration with "react-hook-form"
	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			confidential: formData.confidential ? true : false,
			max_salary: formData.max_salary,
			min_salary: formData.min_salary,
			available_openings: formData.available_openings,
			title: formData.title,
		},
	});

    useEffect( () => {
		initialize();
    }, []);

	const initialize = async () => {
		try {
            const urls = [ '/locations/active', '/industries/active', '/experience/active', '/qualifications/active'];
            const response = await genericController.performGetRequests(urls);
            const { 0: locations, 1: industries, 2: experience, 3: qualifications } = response;

            //check if the request to fetch location doesn't fail before setting values to display
            if(locations && locations.data){
				setLocationLoading(false);
                setLocationOptions(locations.data.map( location => ({label: location.name, value: location.name})));
            }

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(industries && industries.data){
				setIndustryLoading(false);
                setIndustryOptions(industries.data.map( industry => ({label: industry.name, value: industry.name})));
            }

            //check if the request to fetch experience doesn't fail before setting values to display
            if(experience && experience.data){
				setExperienceLoading(false);
                setExperienceLevelOptions(experience.data.map( exp => ({label: exp.name, value: exp.name})));
            }

            //check if the request to fetch certificate/qualifications doesn't fail before setting values to display
            if(qualifications && qualifications.data){
				setQualificationLoading(false);
                setMinimumQualificationOptions(qualifications.data.map( qualification => ({label: qualification.name, value: qualification.name})));
            }
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	}

	const onSubmit = (data) => {
		setPage(++page);
		setFormData(data);
	};

	const confidentialValue = watch("confidential");

	return (
		<>
			<Form className="bg-light p-4 rounded-4 border border-2">
				<Row className="mb-3">
					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="title"
					>
						<Form.Label>Job title</Form.Label>
						<Form.Control
							required
							type="text"
							placeholder="Job title..."
							{...register("title")}
						/>
						<ErrorMessage source={errors.title} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="industry"
					>
						<Form.Label>Industry</Form.Label>
						<Controller
							name="industry"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Select
									placeholder="Industry..."
									{...register("industry")}
									options={industryOptions}
									isLoading={industryLoading}
									onChange={(val) => { return onChange(val.value) }}
								/>
							)}
						/>

						<ErrorMessage source={errors.industry} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="work_type"
					>
						<Form.Label>Work Type</Form.Label>
						<Controller
							name="work_type"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									required
									placeholder="Work type..."
									value={workTypeOptions.find((e) => e.value === value)}
									{...register("work_type")}
									options={workTypeOptions}
									onChange={(val) => onChange(val.value)}
								/>
							)}
						/>
						<ErrorMessage source={errors.work_type} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="min_qualification"
					>
						<Form.Label>Level of Qualification</Form.Label>
						<Controller
							name="min_qualification"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									required
									placeholder="Minimum Qualification..."
									{...register("min_qualification")}
									options={minimumQualificationOptions}
									isLoading={qualificationLoading}
									onChange={(val) => onChange(val.value)}
								/>
							)}
						/>
						<ErrorMessage source={errors.min_qualification} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="location"
					>
						<Form.Label>Location</Form.Label>
						<Controller
							name="location"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									required
									placeholder="Location..."
									{...register("location")}
									onChange={(val) => onChange(val.value)}
									isLoading={locationLoading}
									options={locationOptions}
								/>
							)}
						/>

						<ErrorMessage source={errors.location} />
					</Form.Group>

					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="experience"
					>
						<Form.Label>Years of experience</Form.Label>
						<Controller
							name="experience"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									required
									placeholder="Years of experience..."
									{...register("experience")}
									options={experienceLevelOptions}
									onChange={(val) => onChange(val.value)}
									isLoading={experienceLoading}
								/>
							)}
						/>
						<ErrorMessage source={errors.experience} />
					</Form.Group>

					<div className="col row ps-3">
						<Form.Label>Monthly Salary</Form.Label>
						<div className="ps-2 col">
							<Form.Group controlId="formMonthlySalaryMin">
								<Form.Label>Min</Form.Label>
								<Controller
									name="min_salary"
									control={control}
									disabled={confidentialValue}
									render={({ field }) => (
										<Form.Control
											type="number"
											{...field}
											{...register("min_salary")}
											isInvalid={!!errors.min_salary}
										/>
									)}
								/>
								{errors.min_salary && (
									<Form.Control.Feedback type="invalid">
										{errors.min_salary.message}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</div>
						<div className="col">
							<Form.Group controlId="formMonthlySalaryMax">
								<Form.Label>Max</Form.Label>
								<Controller
									name="max_salary"
									control={control}
									disabled={confidentialValue}
									render={({ field }) => (
										<Form.Control
											type="number"
											{...field}
											{...register("max_salary")}
											isInvalid={!!errors.max_salary}
										/>
									)}
								/>
								{errors.max_salary && (
									<Form.Control.Feedback type="invalid">
										<ErrorMessage source={errors.max_salary} />
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</div>
						<div className="mt-2">
							<Form.Group controlId="formConfidential">
								<Controller
									name="confidential"
									control={control}
									render={({ field }) => (
										<Form.Check
											type="checkbox"
											label="Confidential"
											checked={field.value}
											onChange={(e) => {
												field.onChange(e.target.checked);
											}}
										/>
									)}
								/>
							</Form.Group>
							<ErrorMessage source={errors.confidential} />
						</div>
					</div>
					<Form.Group
						className="my-2 my-sm-3"
						as={Col}
						sm="6"
						controlId="available_openings"
					>
						<Form.Label>No of available Openings</Form.Label>
						<Form.Control
							required
							type="number"
							placeholder="No of available Openings..."
							{...register("available_openings")}
						/>
						<ErrorMessage source={errors.available_openings} />
					</Form.Group>
				</Row>
				<div className="text-center">
					<Button style={{width: '15rem'}}
						variant="primary"
						type="submit"
						onClick={handleSubmit(onSubmit)}
					>
						Next
					</Button>
				</div>
			</Form>
		</>
	);
};

export default PostJobDetailsForm;
