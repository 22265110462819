import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { FaLocationDot } from "react-icons/fa6";
import { toast } from "react-toastify";

import { MyContainer } from "../Components/Styles/GlobalStyle.css";
import WordSpan from "../Components/WordSpan";
import { useAuth } from "../app-context/auth-user-context";
import handleErrMsg from "../Utils/error-handler";
import jobsController from "../controller/jobs-controller";
import { ThreeDotLoading } from "../Components/react-loading-indicators/Indicator";

import Editor from "../Components/quill/quill-editor";
import Quill from 'quill';
import ConfirmDialogComp from "../Components/ConfirmDialogComp";

const Delta = Quill.import('delta');

const JobDescription = () => {
	const navigate = useNavigate();
	const {pathname} = useLocation();
	
    const { handleRefresh, logout } = useAuth();
	
	const [networkRequest, setNetworkRequest] = useState(true);
	const [jobId, setJobId] = useState();
	const [job, setJob] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");

	// Use a ref to access the quill instance directly
	const quillRef = useRef();

    useEffect( () => {
		const pathArr = pathname.split("/");
		setJobId(pathArr[pathArr.length - 2]);
		if(jobId) {
			initialize();
		}
    }, [jobId]);

	const initialize = async () => {
		try {
            const response = await jobsController.searchById(jobId);

            //check if the request to fetch active staff doesn't fail before setting values to display
            if(response && response.data){
				setNetworkRequest(false);
				setJob(response.data);
				const quillData = JSON.parse(response.data.desc);
                // const quillData = response.data.desc;
				let content = new Delta();
				quillData.forEach(element => {
					content.insert(element.insert, element.attributes)
				});
				quillRef.current.setContents(content);
            }

		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				// Incase of 401 Unauthorized, navigate to 404
				if(error.response?.status === 401){
					navigate('/404');
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	};

    const handleOpenModal = () => {
        setDisplayMsg(`Apply for ${job?.title} ?`);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

	const handleConfirmAction = async () => {
        setShowModal(false);
		try {
			setNetworkRequest(true);
			const response = await jobsController.apply(job.id);
			if(response && response.data){
				toast.info(response.data.message);
			}
			setNetworkRequest(false);
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return apply();
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
			setNetworkRequest(false);
		}
	}

	const report = () => {
		navigate( `/jobs/report`, { state: job } );
	}

	const apply = async () => {
		handleOpenModal();
	}

	return (
		<>
			<MyContainer $padding_y="20px">
				<div className="container">
					<div className="mb-4" id="head">
						{ networkRequest && <ThreeDotLoading variant="pulsate" color="#f78419" size="large" /> }
						<h2>
							<WordSpan> {job?.title} </WordSpan>
						</h2>
						<div className="d-flex gap-3 align-items-center text-muted">
							{ job.createdAt && <small className="">{formatDistanceToNow(job?.createdAt, {addSuffix: true})}</small>}
							<span>
								<FaLocationDot /> {job?.JobLocation?.name}
							</span>
							<br />
							<span> {job.work_type === true ? 'Full time' : 'Part Time'} </span>
						</div>
						<div className={`mt-2 ${job.min_salary === 0 && job.max_salary === 0 ? 'text-danger' : 'text-success'}`}>
							{(job.min_salary === 0 && job.max_salary === 0) ? "NGN Confidential" : job?.min_salary?.toLocaleString('en-US', { style: 'currency', currency: 'NGN' }) 
							+ ' up to ' + job?.max_salary?.toLocaleString('en-US', { style: 'currency', currency: 'NGN' }) }
						</div>
					</div>
					<hr />
					<div id="body">
						<h4 className="py-1 mb-2 fw-bold">Job Summary</h4>
						<p className="fw-normal mb-3">
							{job?.summary}.
						</p>
						<hr />
						<h4 className="py-1 mb-2 fw-bold">Job Description</h4>
						<Editor ref={quillRef} readOnly={true} withToolBar={false} />
						<hr className="my-4" />
					</div>
					<Button variant="" className="btn-outline-danger me-3" onClick={() => report()}>
						Report Post
					</Button>
					<Button variant="" className="btn-outline-success" onClick={() => apply()} disabled={networkRequest}>
						Apply
						{networkRequest && <ThreeDotLoading variant="pulsate" color="#f78419" size="large" />}
					</Button>
                	<ConfirmDialogComp show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmAction} message={displayMsg} />
				</div>
			</MyContainer>
		</>
	);
};

export default JobDescription;
