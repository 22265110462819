import React from "react";
import Showcase from "../Components/Showcase/Showcase";
import { capitalizeFirstLetter, slashToSpace } from "../Utils/helpers";
import { useLocation } from "react-router-dom";
import { ImLocation } from "react-icons/im";
import { FaPhone } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { ContactGridWrapper } from "../Components/Styles/GlobalStyle.css";
import WordSpan from "../Components/WordSpan";



const Contact = () => {
	return (
		<>
			<Showcase align="start" text="center" height={"40vh"}>
				<div className="container">
					<h1 className="px-4 bungee-regular">
						<WordSpan className="bungee-regular">{capitalizeFirstLetter(slashToSpace(useLocation().pathname))} Us</WordSpan>
					</h1>
				</div>
			</Showcase>

			<div className="container mt-5 py-3">
				<ContactGridWrapper>
					<div className="row text-center">
						<div className="col-12 col-md-4">
							<div className="border rounded-4 py-5 my-1 d-flex flex-column justify-content-center align-items-center" style={{ height: "20rem" }}>
								<div className="p-3">
									<ImLocation size={"70px"} />
								</div>
								<h3>ADDRESS</h3>

								<p>
									Venue: No, 75 Metroeyes building, opposite Access Bank, along
									MTN office, Oke-ilewo, Abeokuta..
								</p>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="border rounded-4 py-my-1 5 bg_1 text-black d-flex flex-column justify-content-center align-items-center" style={{ height: "20rem" }}>
								<div className="p-3">
									<FaPhone size={"70px"} />
								</div>
								<h3>PHONE</h3>

								<p className="">Mobile: 09169975272</p>
								<p className="">Whatsapp: 09169975272</p>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="border rounded-4 py-5 my-1 d-flex flex-column justify-content-center align-items-center" style={{ height: "20rem" }}>
								<div className="p-3">
									<SiGmail size={"70px"} />
								</div>
								<h3>EMAIL</h3>

								<p>
									admin@tapafricanow.com
								</p>
							</div>
						</div>
					</div>

				</ContactGridWrapper>
			</div>

			<div className="position-fixed bottom-0 end-0 bg-success m-2 p-2 rounded-pill">
				<a
					target={"_blank"}
					rel={"noreferrer"}
					className="text-white text-decoration-none"
					href="https://wa.me/2349169975272"
				>
					Chat with Us
				</a>
			</div>
		</>
	);
};

export default Contact;
