import { useState } from "react";
import { Badge, Button, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { formatDistanceToNow } from "date-fns";
import { toast } from "react-toastify";

import WordSpan from "../Components/WordSpan";
import { MyContainer } from "../Components/Styles/GlobalStyle.css";
import ErrorMessage from "../Components/ErrorMessage";
import ConfirmDialogComp from "../Components/ConfirmDialogComp";
import { useAuth } from "../app-context/auth-user-context";
import handleErrMsg from "../Utils/error-handler";
import { ThreeDotLoading } from "../Components/react-loading-indicators/Indicator";
import jobsController from "../controller/jobs-controller";

const JobReport = () => {
    const navigate = useNavigate();
	const {state} = useLocation();

    const { handleRefresh, logout } = useAuth();
    
    const [showModal, setShowModal] = useState(false);
    const [displayMsg, setDisplayMsg] = useState("");
    const [reportedData, setReportedData] = useState();
	const [networkRequest, setNetworkRequest] = useState(false);

    const schema = yup.object().shape({
        reason: yup.string().max(255, "Maximum of 255 characters").required("Reason phrase is required!")
    });

    const handleOpenModal = (id, name) => {
        setDisplayMsg(`Report ${state.title}?`);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleConfirmAction = async () => {
        setShowModal(false);
        try {
            setNetworkRequest(true);
            const response = await jobsController.report(reportedData);
            if(response && response.data){
                toast.info(response.data.message);
            }
            setNetworkRequest(false);
            navigate('/jobs');
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                  await handleRefresh();
                  return handleConfirmAction();
                }
                toast.error(handleErrMsg(error).msg);
                setNetworkRequest(false);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    };
  

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

	const onSubmit = (data) => {
        data.id = state.id;
        setReportedData(data);
        handleOpenModal();
	}

    return (
        <MyContainer $padding_y="40px" className="container">
            
			<Form className="bg-light p-4 rounded-4 border border-2 mb-5">
				<h4 className="lead">Report Job Below</h4>
				<Row className="mb-3">
					<Form.Group className="my-2 my-sm-3" controlId="summary">
						<Form.Label className="fw-bold">
                            Please provide reasons and lets us know more. Kindly be aware that we do not take false report lightly
                        </Form.Label>
						<textarea
							style={{ minHeight: "90px" }}
							className="form-control"
							placeholder="comment here..."
							{...register("reason")}
						></textarea>
						<ErrorMessage source={errors.reason} />
					</Form.Group>
				</Row>
				<div className="text-center">
                    <Button variant="" className="btn-outline-danger me-3" disabled={networkRequest} onClick={handleSubmit(onSubmit)}>
                        { networkRequest && <ThreeDotLoading color="#ffffff" size="medium" /> }
                        { !networkRequest && `Report Post` }
                    </Button>
				</div>
			</Form>
            <div className="btn text-start border border-secondary-subtle p-3 rounded-3 w-100 shadow" >
                <div className="mb-2">
                    <Badge
                        className={`ms-auto text-dark fw-bold bg-primary-subtle fw-bolder align-self-start p-2 me-3`}
                    >
                        <span>{formatDistanceToNow(state.createdAt, {addSuffix: true})}</span>
                    </Badge>
                    <Badge
                        className={`ms-auto text-dark fw-bold bg-success-subtle fw-bolder align-self-start p-2`}
                    >
                        <span> {state.available_openings} position{state.available_openings > 1 && "s"} </span>
                    </Badge>
                </div>
                <div className="mb-2">
                    <h3 className="py-2">
                        <WordSpan> {state.title} </WordSpan>
                    </h3>
                    <div className="d-flex gap-sm-3 flex-column flex-sm-row">
                        <small className="fw-bold"> {state.JobLocation.name} </small>
                        <small className="fw-bold"> {state.work_type === true ? "full time" : "part time"} </small>
                        <small className={(state.min_salary === 0 && state.max_salary === 0) ? 'text-danger' : '' + " fw-bold"}> 
                            {(state.min_salary === 0 && state.max_salary === 0) ? "Confidential" : `${state.min_salary} up to ${state.max_salary}`}
                        </small>
                    </div>
                </div>
                <div className="mb-2">
                    <p>
                        {state.summary}
                    </p>
                </div>
                <Badge className={`ms-auto bg-success-subtle text-secondary fw-bolder align-self-start p-2`}>
                    {state.JobIndustry.name}
                </Badge>
            </div>

            <ConfirmDialogComp show={showModal}  handleClose={handleCloseModal} handleConfirm={handleConfirmAction} message={displayMsg} />
        </MyContainer>
    );
}

export default JobReport;