import { Pagination } from 'react-bootstrap';
// import _ from 'lodash';
import { useState, useEffect } from 'react';

export function paginationAlgo(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    return items.slice(startIndex,  startIndex + pageSize);
    // return _(items).slice(startIndex).take(pageSize).value();
}

export default function DataPagination(props) {
    
     /* itemCount is the total number of items to be displayed in the table
        pageSize is the number of items per page to display
        neighbour is the number of pagination items to be added before and after the current/selected pagination item
        data is the whole data supplied to the pagination, where slices/pages are cut from
        pageChanged is a supplied callback func from DataPagination to get data to show on current selected page
    */
    
    const { itemCount, pageSize, pageNumber = 1, pageChanged, data } = props;
    const pageCount = Math.ceil(itemCount / pageSize);
    const neighbour = 2, range = [];
    const [currentPage, setCurrentPage] = useState(1);
    
    const getPageData = (pageNumber) => {
        const startIndex = (pageNumber - 1) * pageSize;
        setCurrentPage(pageNumber);
        return pageChanged(data.slice(startIndex, startIndex + pageSize), pageNumber);
        // return pageChanged(_(data).slice(startIndex).take(pageSize).value());
    }

    useEffect(() => {
        //  set current page back to 1 as the table will be re-rendered and back to first page
        setCurrentPage(pageNumber);
        getPageData(pageNumber);
    }, [data, pageNumber]);

    if (pageCount <= 1) return null;

    let paginationItems = [];

    for (let i = Math.max(2, (currentPage - neighbour)); i <= Math.min((pageCount - 1), (currentPage + neighbour)); i++){
        range.push(i);
    }
    
    if ((currentPage - neighbour) > 2) {
        range.unshift('...');
    }

    if ((currentPage + neighbour) < (pageCount - 1)) {
        range.push('...');
    }

    range.unshift(1);
    if (pageCount !== 1) {
        range.push(pageCount);
    }

    range.map((pageNumber, index) => {
        !isNaN(pageNumber) ?
            paginationItems.push(
                <Pagination.Item key={index} active={pageNumber === currentPage} onClick={(() => getPageData(pageNumber))}>
                    {pageNumber}
                </Pagination.Item>                
            ) :
            paginationItems.push(
                <Pagination.Ellipsis key={index} />                
            )
    });

    return (
        <Pagination>
            {paginationItems}
        </Pagination>
    );
}